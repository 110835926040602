import * as types from 'const/action.types'

export function xef(state = {}, action) {
    switch (action.type) {
        case types.XEF_SUCCESS:
            return action.data
        case types.XEF_ADD_EVENTS: {
            let {
                activo,
                proximaproduccion,
                avisocapacidad,
                horaventa,
                productosStock,
                productos,
                desviacion,
                tiposmaquina,
            } = state
            const arr = state.producciones ? [].concat([...state.producciones]) : []
            action.events
                .filter(
                    (f) =>
                        f.evento &&
                        (f.evento.startsWith('PRD_') ||
                            f.evento === 'STK_PROD' ||
                            f.evento === 'PRV_DESV' ||
                            f.evento === 'VTA_HORA'),
                )
                .forEach((e) => {
                    if (e.evento === 'PRD_PAUS' || e.evento === 'PRD_REST') {
                        activo = e.payload.activo
                        proximaproduccion = e.payload.proximaproduccion
                    }
                    if (e.evento === 'VTA_HORA') {
                        horaventa = e.payload
                    }
                    if (e.evento === 'PRV_DESV') {
                        desviacion = e.payload
                    }
                    if (e.evento === 'STK_PROD' && Array.isArray(productosStock)) {
                        const stock = e.payload
                        let found = false
                        productosStock.forEach((i, index) => {
                            if (i && i.idProducto === stock.idProducto) {
                                productosStock[index] = stock
                                found = true
                            }
                        })
                        if (!found) {
                            productosStock.push(stock)
                        }
                    } else if (e.evento === 'PRD_CAPA' || e.evento === 'PRD_CPOF' || e.evento === 'PRD_CPON') {
                        avisocapacidad = e.payload.avisocapacidad
                    } else {
                        const produccion = e.payload
                        let found = false
                        arr.forEach((i, index) => {
                            if (i && i.id === produccion.id) {
                                arr[index] = produccion
                                found = true
                            }
                        })
                        if (!found) {
                            arr.push(produccion)
                        }
                    }
                })
            return {
                activo: activo,
                proximaproduccion: proximaproduccion,
                avisocapacidad: avisocapacidad,
                horaventa: horaventa,
                productosStock: Array.isArray(productosStock) ? [...productosStock] : [],
                productos: productos,
                desviacion: desviacion,
                tiposmaquina: tiposmaquina,
                producciones: [...arr],
            }
        }
        default:
            return state
    }
}

export function xefReferenceTime(state = '', action) {
    switch (action.type) {
        case types.XEF_TIME_REFERENCE:
            return action.time
        default:
            return state
    }
}

export function xeflocal(state = '', action) {
    switch (action.type) {
        case types.XEF_SET_LOCAL:
            return action.local
        default:
            return state
    }
}

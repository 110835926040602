export const VERSION = 'VERSION'
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS'
export const LOG_OUT = 'LOG_OUT'
export const KEEP_SESSION = 'KEEP_SESSION'
export const LOAD_BASIC_INFO_SECURITY = 'LOAD_BASIC_INFO_SECURITY'
export const LOAD_SESSION_USER_SUCCESS = 'LOAD_SESSION_USER_SUCCESS'
export const LOAD_QUOTA = 'LOAD_QUOTA'
export const TUTORIAL = 'TUTORIAL'
export const WARNINGS = 'WARNINGS'
export const SHOW_VOICE_CONTROL = 'SHOW_VOICE_CONTROL'

export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS'
export const USERS_SUCCESS = 'USERS_SUCCESS'

export const LOAD_PROVIDER_SUCCESS = 'LOAD_PROVIDER_SUCCESS'
export const PROVIDERS_SUCCESS = 'PROVIDERS_SUCCESS'

export const LOAD_REFERENCE_SUCCESS = 'LOAD_REFERENCE_SUCCESS'
export const REFERENCES_SUCCESS = 'REFERENCES_SUCCESS'

export const LOAD_TEMPLATES_SUCCESS = 'LOAD_TEMPLATES_SUCCESS'
export const TEMPLATE_SUCCESS = 'TEMPLATE_SUCCESS'

export const XEF_SUCCESS = 'XEF_SUCCESS'
export const XEF_TIME_REFERENCE = 'XEF_TIME_REFERENCE'
export const XEF_ADD_EVENTS = 'XEF_ADD_EVENTS'
export const XEF_SET_LOCAL = 'XEF_SET_LOCAL'

export const LOAD_MACHINE_SUCCESS = 'LOAD_MACHINE_SUCCESS'
export const MACHINES_SUCCESS = 'MACHINES_SUCCESS'

export const MACHINESLIST_SUCCESS = 'MACHINESLIST_SUCCESS'
export const LOAD_TYPE_MACHINE_SUCCESS = 'LOAD_TYPE_MACHINE_SUCCESS'
export const TYPE_MACHINES_SUCCESS = 'TYPE_MACHINES_SUCCESS'

export const LOAD_LOCAL_SUCCESS = 'LOAD_LOCAL_SUCCESS'
export const LOAD_LOCAL_INFO_SUCCESS = 'LOAD_LOCAL_INFO_SUCCESS'
export const LOCALS_INFO_SUCCESS = 'LOCALS_INFO_SUCCESS'
export const LOCALS_SUCCESS = 'LOCALS_SUCCESS'

export const LOAD_PRODUCT_SUCCESS = 'LOAD_PRODUCT_SUCCESS'
export const PRODUCTS_SUCCESS = 'PRODUCTS_SUCCESS'
export const LOAD_WORKSTATION_SUCCESS = 'LOAD_WORKSTATION_SUCCESS'

export const LOAD_FORECAST_SUCCESS = 'LOAD_FORECAST_SUCCESS'
export const FORECASTS_SUCCESS = 'FORECASTS_SUCCESS'

export const LOAD_DISPLAY_SUCCESS = 'LOAD_DISPLAY_SUCCESS'
export const DISPLAYS_SUCCESS = 'DISPLAYS_SUCCESS'

export const LOAD_LIST_SUCCESS = 'LOAD_LIST_SUCCESS'

export const LOAD_TERMOMETRE_SUCCESS = 'LOAD_TERMOMETRE_SUCCESS'
export const TERMOMETRES_SUCCESS = 'TERMOMETRES_SUCCESS'
export const LOAD_TERMOMETRE_FIELDS_SUCCESS = 'LOAD_TERMOMETRE_FIELDS_SUCCESS'

export const LOAD_CLIENT_SUCCESS = 'LOAD_CLIENT_SUCCESS'
export const LOAD_CLIENTS_SUCCESS = 'LOAD_CLIENTS_SUCCESS'

export const INSTANCE_FETCH_DATA = 'INSTANCE_FETCH_DATA_SUCCESS'
export const INSTANCES_FETCH_DATA = 'INSTANCES_FETCH_DATA'
export const INSTANCE_HANDLE_CHANGE = 'INSTANCE_HANDLE_CHANGE'
export const INSTANCE_HANDLE_CHANGE_META = 'INSTANCE_HANDLE_CHANGE_META'
export const CLEAR_INSTANCE_VALUES = 'CLEAR_INSTANCE_VALUES'
export const EXECUTE_INSTANCE_TRANSITION = 'EXECUTE_INSTANCE_TRANSITION'
export const EXECUTING_INSTANCE_TRANSITION = 'EXECUTING_INSTANCE_TRANSITION'
export const INSTANCE_KEEP_ATTACHED = 'INSTANCE_KEEP_ATTACHED'
export const INSTANCE_SAVE_ATTACHED = 'INSTANCE_SAVE_ATTACHED'

export const PROCESS_FETCH_DATA = 'PROCESS_FETCH_DATA_SUCCESS'
export const PROCESS_MESSAGES = 'PROCESS_MESSAGES'
export const SET_PARAMETERS_LOAD = 'SET_PARAMETERS_LOAD'
export const INSTANCE_OPENED_FETCH_DATA = 'INSTANCE_OPENED_FETCH_DATA'

export const MANUAl_PROCESS_FETCH_DATA = 'MANUAl_PROCESS_FETCH_DATA_SUCCESS'

export const MANAGE_PROCESS_FETCH_DATA = 'MANAGE_PROCESS_FETCH_DATA_SUCCESS'
export const MANAGE_PROCESS_ADD_FAMILY = 'MANAGE_PROCESS_ADD_FAMILY'
export const MANAGE_PROCESS_EDIT_FAMILY = 'MANAGE_PROCESS_EDIT_FAMILY'
export const MANAGE_PROCESS_DUPLICATE_FAMILY = 'MANAGE_PROCESS_DUPLICATE_FAMILY'
export const MANAGE_PROCESS_DELETE_FAMILY = 'MANAGE_PROCESS_DELETE_FAMILY'
export const MANAGE_PROCESS_ADD_PROCESS = 'MANAGE_PROCESS_ADD_PROCESS'
export const MANAGE_PROCESS_EDIT_PROCESS = 'MANAGE_PROCESS_EDIT_PROCESS'
export const MANAGE_PROCESS_DUPLICATE_PROCESS = 'MANAGE_PROCESS_DUPLICATE_PROCESS'
export const MANAGE_PROCESS_DELETE_PROCESS = 'MANAGE_PROCESS_DELETE_PROCESS'

export const MANAGE_FORM_FETCH_DATA = 'MANAGE_FORM_FETCH_DATA'

export const CATALOG_FETCH_DATA = 'CATALOG_FETCH_DATA_SUCCESS'
export const CATALOGS_FETCH_DATA_SUCCESS = 'CATALOGS_FETCH_DATA_SUCCESS'

export const XHEF_LOAD_SUCCESS = 'XHEF_LOAD_SUCCESS'

export const SET_LANG = 'SET_LANG'
export const SET_WINDOW = 'SET_WINDOW'
export const SET_TOOLS = 'SET_TOOLS'
export const SET_SEARCH = 'SET_SEARCH'
export const SET_FILTER = 'SET_FILTER'
export const SET_SEARCH_ARRAY = 'SET_SEARCH_ARRAY'
export const SET_PREVIOUS_WINDOWS = 'SET_PREVIOUS_WINDOWS'
export const SET_INVENTORY_WINDOW = 'SET_INVENTORY_WINDOW'

export const SET_WARNING_READED = 'SET_WARNING_READED'
export const SET_ALERT_TAB = 'SET_ALERT_TAB'

export const LOAD_ALERTS_SUCCESS = 'LOAD_ALERTS_SUCCESS'
export const ALERTS_DISMISS_SUCCESS = 'ALERTS_DISMISS_SUCCESS'
export const ALERTS_DELETE_SUCCESS = 'ALERTS_DELETE_SUCCESS'

export const SET_BLUETOOTH_USED = 'SET_BLUETOOTH_USED'

export const REPORT_SEARCH_FIELDS_FETCH_DATA = 'REPORT_SEARCH_FIELDS_FETCH_DATA'
export const REPORT_SEARCH_VALUES_FETCH_DATA = 'REPORT_SEARCH_VALUES_FETCH_DATA'

export const REPORT_DECREASE_VALUES_FETCH_DATA = 'REPORT_DECREASE_VALUES_FETCH_DATA'
export const REPORT_DECREASE_LOCALS_FETCH_DATA = 'REPORT_DECREASE_LOCALS_FETCH_DATA'

export const REPORT_LOT_FETCH_DATA = 'REPORT_LOT_FETCH_DATA'
export const REPORT_APPCC_FETCH_DATA = 'REPORT_APPCC_FETCH_DATA'
export const REPORT_MACHINES_FETCH_DATA = 'REPORT_MACHINES_FETCH_DATA'
export const REPORT_EMPLOYEE_FETCH_DATA = 'REPORT_EMPLOYEE_FETCH_DATA'

export const LOAD_INDICATORS_SUCCESS = 'LOAD_INDICATORS_SUCCESS'
export const DASHBOARDS_SUCCESS = 'DASHBOARDS_SUCCESS'
export const DASHBOARDS_CURRENT_TAB = 'DASHBOARDS_CURRENT_TAB'

export const LOAD_COMMUNICATION_SUCCESS = 'LOAD_COMMUNICATION_SUCCESS'
export const COMMUNICATIONS_SUCCESS = 'COMMUNICATIONS_SUCCESS'
export const SET_FILTER_COMMUNICATION_SUCCESS = 'SET_FILTER_COMMUNICATION_SUCCESS'
export const PENDING_COMMUNICATION_SUCCESS = 'PENDING_COMMUNICATION_SUCCESS'
export const COMMUNICATIONS_EVT_COM_DELE = 'COMMUNICATIONS_EVT_COM_DELE'
export const COMMUNICATIONS_EVT_COM_ADD = 'COMMUNICATIONS_EVT_COM_ADD'
export const COMMUNICATIONS_EVT_COMMT_ADD = 'COMMUNICATIONS_EVT_COMMT_ADD'

export const LOAD_MESSAGE_SUCCESS = 'LOAD_MESSAGE_SUCCESS'
export const MESSAGES_SUCCESS = 'MESSAGES_SUCCESS'
export const MESSAGE_MARK_AS_READED = 'MESSAGE_MARK_AS_READED'
export const MESSAGES_EVT_COM_ADD = 'MESSAGES_EVT_COM_ADD'
export const MESSAGES_EVT_CMNT_ADD = 'MESSAGES_EVT_CMNT_ADD'

export const LOAD_VISIBILITY_SUCCESS = 'LOAD_VISIBILITY_SUCCESS'

export const LOAD_STS_SUCCESS = 'LOAD_STS_SUCCESS'

export const REPORT_PRODUCTIVITY_FETCH_DATA = 'REPORT_PRODUCTIVITY_FETCH_DATA'

export const REPORT_STS_FETCH_DATA = 'REPORT_STS_FETCH_DATA'

export const REPORT_SAVE_FETCH_DATA = 'REPORT_SAVE_FETCH_DATA'
export const LOAD_REPORT_PROCESS_SUCCESS = 'LOAD_REPORT_PROCESS_SUCCESS'
export const LOAD_REPORT_PROCESS_LIST = 'LOAD_REPORT_PROCESS_LIST'
export const LOAD_REPORT_AUDIT_SUCCESS = 'LOAD_REPORT_AUDIT_SUCCESS'

export const LOAD_EMPLOYEE_SUCCESS = 'LOAD_EMPLOYEE_SUCCESS'
export const EMPLOYEES_SUCCESS = 'EMPLOYEES_SUCCESS'

export const LOAD_ROLE_SUCCESS = 'LOAD_ROLE_SUCCESS'
export const ROLES_SUCCESS = 'ROLES_SUCCESS'
export const LOAD_APP_ROLES_SUCCESS = 'LOAD_APP_ROLES_SUCCESS'

export const LOAD_ONBOARDING_SUCCESS = 'LOAD_ONBOARDING_SUCCESS'
export const ONBOARDING_PERMISSIONS_SUCCESS = 'ONBOARDING_PERMISSIONS_SUCCESS'

export const IS_LOADING = 'IS_LOADING'
export const IS_SAVING = 'IS_SAVING'
export const IS_HIDDEN = 'IS_HIDDEN'
export const SHOW_WARNING = 'SHOW_WARNING'
export const SET_ERROR = 'SET_ERROR'
export const SET_MESSAGE = 'SET_MESSAGE'
export const SET_DICTIONARY = 'SET_DICTIONARY'
export const SET_PRINTER = 'SET_PRINTER'
export const ADD_TAG_TO_PRINT = 'ADD_TAG_TO_PRINT'
export const REMOVE_TAG_TO_PRINT = 'REMOVE_TAG_TO_PRINT'

export const TAGS_SUCCESS = 'TAGS_SUCCESS'
export const TAGS_IS_LOADING = 'TAGS_IS_LOADING'

export const PRODUCTS_TAGS_SUCCESS = 'PRODUCTS_TAGS_SUCCESS'
export const PRODUCTS_TAGS_LISTS_SUCCESS = 'PRODUCTS_TAGS_LISTS_SUCCESS'
export const PRODUCT_TAG_INFO_SUCCESS = 'PRODUCT_TAG_INFO_SUCCESS'

export const METADATA_PRODUCTS_TAG_LISTS_SUCCESS = 'METADATA_PRODUCTS_TAG_LISTS_SUCCESS'
export const INTERVALS_SUCCESS = 'INTERVALS_SUCCESS'

export const UNFROZEN_IS_LOADING = 'UNFROZEN_IS_LOADING'
export const UNFROZEN_FORECAST_FETCH_DATA_SUCCESS = 'UNFROZEN_FORECAST_FETCH_DATA_SUCCESS'
export const UNFROZEN_INFO_FETCH_DATA_SUCCESS = 'UNFROZEN_INFO_FETCH_DATA_SUCCESS'
export const UNFROZEN_HISTORICAL_FETCH_DATA_SUCCESS = 'UNFROZEN_HISTORICAL_FETCH_DATA_SUCCESS'
export const UNFROZEN_MESSAGES_ALERT = 'UNFROZEN_MESSAGES_ALERT'

export const CATALOGS_TAGS_SUCCESS = 'CATALOGS_TAGS_SUCCESS'
export const CATALOG_TAG_INFO_SUCCESS = 'CATALOG_TAG_INFO_SUCCESS'

export const SET_HELP_OPTIONS = 'SET_HELP_OPTIONS'
export const SET_HELP_OPTION = 'SET_HELP_OPTION'
export const SET_HELP_OPTION_INFO = 'SET_HELP_OPTION_INFO'

export const SET_NUM_PAD = 'SET_NUM_PAD'

export const LOAD_RECIPES_SUCCESS = 'LOAD_RECIPES_SUCCESS'
export const LOAD_RECIPE_SUCCESS = 'LOAD_RECIPE_SUCCESS'
export const LOAD_TRACEABILITY_SUCCESS = 'LOAD_TRACEABILITY_SUCCESS'
export const LOAD_PREPARATION_SUCCESS = 'LOAD_PREPARATION_SUCCESS'

export const LOAD_RECIPE_ELABORATIONS_SUCCESS = 'LOAD_RECIPE_ELABORATIONS_SUCCESS'
export const POINTS_SUCCESS = 'POINTS_SUCCESS'

export const LOAD_REPORT_TRACEABILITY_SUCCESS = 'LOAD_REPORT_TRACEABILITY_SUCCESS'
export const LOAD_REPORT_TRACEABILITY_INFO_SUCCESS = 'LOAD_REPORT_TRACEABILITY_INFO_SUCCESS'

export const SET_PAC = 'SET_PAC'
export const MODIFY_PAC = 'MODIFY_PAC'

export const LOAD_SUCCESS_DOCUMENTS = 'LOAD_SUCCESS_DOCUMENTS'

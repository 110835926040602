import * as types from 'const/action.types'

export function setBluetooth(state = false, action) {
    switch (action.type) {
        case types.SET_BLUETOOTH_USED:
            return action.used
        default:
            return state
    }
}

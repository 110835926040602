import React from 'react'
import { connect } from 'react-redux'
import { IntlProvider } from 'react-intl'

//import messages_es from '../locale-data/es.json'
import messages_en from '../locale-data/en.json'

class Inter extends React.Component {
    render() {
        let { lang, messages, children } = this.props
        return (
            <IntlProvider key={lang} locale={lang} messages={messages}>
                {children}
            </IntlProvider>
        )
    }
}

// This function will map the current redux state to the props for the component that it is "connected" to.
// When the state of the redux store changes, this function will be called, if the props that come out of
// this function are different, then the component that is wrapped is re-rendered.
function mapStateToProps(state) {
    let lang = state.locales ? state.locales.lang : 'en'
    let messages = state.locales ? state.locales.messages : 'en'
    if (messages === null) {
        lang = 'en'
        messages = messages_en
    }
    return { lang, messages }
}

export default connect(mapStateToProps)(Inter)

import * as types from 'const/action.types'

export function product(state = {}, action) {
    switch (action.type) {
        case types.LOAD_PRODUCT_SUCCESS:
            return action.data
        default:
            return state
    }
}

export function products(state = [], action) {
    switch (action.type) {
        case types.PRODUCTS_SUCCESS:
            return action.data
        default:
            return state
    }
}

export function workstation(state = [], action) {
    switch (action.type) {
        case types.LOAD_WORKSTATION_SUCCESS:
            return action.data
        default:
            return state
    }
}

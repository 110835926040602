import * as types from 'const/action.types'

export function documents(state = {}, action) {
    switch (action.type) {
        case types.LOAD_SUCCESS_DOCUMENTS:
            return action.data
        default:
            return state
    }
}

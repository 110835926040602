import * as types from 'const/action.types'
import { del, get, post, put } from 'api/_basic'
import { setError, setLoading } from './application.actions'

export function catalogsFetchDataSuccess(items) {
    return {
        type: types.CATALOGS_FETCH_DATA_SUCCESS,
        items,
    }
}

export function catalogFetchDataSuccess(items) {
    return {
        type: types.CATALOG_FETCH_DATA,
        items,
    }
}

export function loadCatalogs() {
    return async (dispatch) => {
        dispatch(setLoading(true))
        dispatch(setError(false, ''))
        return get(process.env.REACT_APP_API_CATALOG)
            .then((response) => {
                dispatch(setLoading(false))
                if (response.error !== undefined) {
                    throw Error(response)
                }
                return response
            })
            .then((items) => {
                dispatch(setError(false, ''))
                dispatch(catalogsFetchDataSuccess(items))
            })
            .catch((error) => {
                dispatch(setError(error.type ? error.type : true, error.message))
            })
    }
}

export function getCatalog(id) {
    return async (dispatch) => {
        dispatch(setLoading(true))
        dispatch(setError(false, ''))
        dispatch(catalogFetchDataSuccess({}))
        return get(process.env.REACT_APP_API_CATALOG + '/' + id)
            .then((response) => {
                dispatch(setLoading(false))
                if (response.error !== undefined) {
                    throw Error(response)
                }
                return response
            })
            .then((items) => {
                dispatch(setError(false, ''))
                dispatch(catalogFetchDataSuccess(items))
            })
            .catch((error) => {
                dispatch(setError(error.type ? error.type : true, error.message))
            })
    }
}

export function editCatalog(catalog, toDelete) {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const body = {
            codigo: catalog.codigo,
            nombre: catalog.nombre,
            descripcion: catalog.descripcion,
            orden: catalog.orden,
            sistema: catalog.sistema,
            permisos: catalog.permisos,
            maximapuntuacion: catalog.valores.reduce((max, v) => {
                max = Math.max(max, v.puntuacion)
                return max
            }, 0),
        }
        const json = await put(process.env.REACT_APP_API_CATALOG + '/' + catalog.codigo, body)
            .then((response) => {
                dispatch(setLoading(false))
                return response
            })
            .catch((error) => {
                console.error(error)
                dispatch(setLoading(false))
                throw error
            })

        toDelete.forEach((v) => {
            if (Number.isInteger(v)) {
                del(process.env.REACT_APP_API_CATALOG_FIELD + '/' + v)
            }
        })

        catalog.valores.forEach((v, index) => {
            if (Number.isInteger(v.id)) {
                put(process.env.REACT_APP_API_CATALOG_FIELD + '/' + v.id, {
                    valor: v.valor,
                    puntuacion: v.puntuacion ? v.puntuacion : null,
                    orden: index + 1,
                })
            } else {
                post(process.env.REACT_APP_API_CATALOG_FIELD + '/' + catalog.codigo, {
                    valor: v.valor,
                    puntuacion: v.puntuacion ? v.puntuacion : 0,
                    orden: index + 1,
                })
            }
        })

        return json
    }
}

export function addCatalog(catalog) {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const body = {
            codigo: catalog.codigo,
            nombre: catalog.nombre,
            descripcion: catalog.descripcion,
            orden: catalog.orden,
            sistema: catalog.sistema,
            permisos: catalog.permisos,
            maximapuntuacion: catalog.valores.reduce((max, v) => {
                max = Math.max(max, v.puntuacion)
                return max
            }, 0),
        }
        const json = await post(process.env.REACT_APP_API_CATALOG, body)
            .then((response) => {
                dispatch(setLoading(false))
                return response
            })
            .catch((error) => {
                console.error(error)
                dispatch(setLoading(false))
                throw error
            })
        //OJO REVIEW
        catalog.codigo = json.codigo
        catalog.valores.forEach((v, index) => {
            post(process.env.REACT_APP_API_CATALOG_FIELD + '/' + catalog.codigo, {
                valor: v.valor,
                puntuacion: v.puntuacion ? v.puntuacion : 0,
                orden: index + 1,
            })
        })
        return json
    }
}

/*eslint-disable import/default */
import "babel-polyfill";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import JssProvider from "react-jss/lib/JssProvider";


import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import ConnectedIntlProvider from "./intl/ConnectedIntlProvider";

// eslint-disable-next-line
import colors from './styles/colors.css'
// eslint-disable-next-line
import fonts from './styles/fonts.css'
// eslint-disable-next-line
import common from './styles/common.css'

import { persistor, store } from "./store/configure.store";

import App from "./app";
import Layout from "./layout";
import theme from "./theme";

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ConnectedIntlProvider>
                <JssProvider>
                    <Layout>
                        <ThemeProvider theme={theme}>
                            <CssBaseline />
                            <App />
                        </ThemeProvider>
                    </Layout>
                </JssProvider>
            </ConnectedIntlProvider>
        </PersistGate>
    </Provider>,
    document.querySelector("#root")
);

import React from "react";

import { useDispatch, useSelector } from "react-redux";
import Loading from "./components/dialogs/loading";
import Snackbar from "./components/labels/Snackbar";
import { setError, setMessage } from "./actions/application.actions";

const Basic = props => {

    const loading = useSelector(state => state.loading);
    const error = useSelector(state => state.error);
    const global_message = useSelector(state => state.global_message);
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(setError(false, {}));
        dispatch(setMessage(false, {}));
    }

    return (
        <div id="main">
            {loading && <Loading open opacity />}
            {(error && error.hasErrored && error.error && error.error.error) && <Snackbar
                open={true}
                variant={error.error.errorType ? error.error.errorType : "error"}
                text={error.error.error}
                nonavbar={true}
                onClose={handleClose}
            />}
            {(global_message && global_message.hasErrored && global_message.error) &&
                <Snackbar
                    open={true}
                    variant={global_message.hasErrored ? global_message.hasErrored : "error"}
                    text={global_message.error}
                    nonavbar={true}
                    onClose={handleClose}
                />}
            {props.children}
        </div>
    );
};
export default Basic;

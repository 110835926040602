import * as types from 'const/action.types'

export function client(state = {}, action) {
    switch (action.type) {
        case types.LOAD_CLIENT_SUCCESS:
            return action.data
        default:
            return state
    }
}

export function clients(state = [], action) {
    switch (action.type) {
        case types.LOAD_CLIENTS_SUCCESS:
            return action.data
        default:
            return state
    }
}

import * as types from 'const/action.types'

function flatTree(list) {
    if (list && list.length > 0) {
        return list.reduce((arr, node) => {
            const childs = node.hijos
            arr.push(node)
            if (childs && childs.length > 0) {
                arr = arr.concat(flatTree(childs))
            }
            return arr
        }, [])
    } else {
        return []
    }
}

export function pac(state = {}, action) {
    switch (action.type) {
        case types.SET_PAC:
            const pac = action.data
            if (pac && pac.campos) {
                pac.fields = flatTree(pac.campos)
            }
            return pac
        case types.MODIFY_PAC:
            return action.data
        default:
            return state
    }
}

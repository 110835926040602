import * as types from 'const/action.types'

export function typemachine(state = {}, action) {
    switch (action.type) {
        case types.LOAD_TYPE_MACHINE_SUCCESS:
            return action.data
        default:
            return state
    }
}

export function typemachines(state = [], action) {
    switch (action.type) {
        case types.TYPE_MACHINES_SUCCESS:
            return action.data
        default:
            return state
    }
}

import * as actions from 'const/action.types'
import { types } from 'const/types.task'
import {
    HideOld,
    RunRules,
    estaSuspenso,
    calculoPendiente,
    calculoPendienteCampo,
    calculoPendienteRecursivo,
    calculoPuntuacion,
    calculoAlerta,
    isOutRangeDate,
    isOutRangeDateTime,
    isOutRangeReal,
} from 'functions/forms'

function formatDate(d) {
    function pad(s) {
        return s < 10 ? '0' + s : s
    }
    return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-')
}

function formatTime(d) {
    function pad(s) {
        return s < 10 ? '0' + s : s
    }
    return [pad(d.getHours()), pad(d.getMinutes()), pad(d.getSeconds())].join(':')
}

function findItem(arr, codigo) {
    let item = undefined
    arr.forEach((i) => {
        if (i.hijos && i.hijos.length > 0) {
            let aux = findItem(i.hijos, codigo)
            if (aux) {
                item = aux
            }
        }
        if (i.codigo === codigo) {
            item = i
        }
    })

    return item
}

function deactiveChilds(item, checked) {
    item.desactivado = checked
    if (checked) {
        item.valor = undefined
        item.puntuacionposible = 0
        item.puntuacionefectiva = 0
    } else {
        item.puntuacionefectiva = 0
        item.puntuacionposible = item.puntuacion
    }
    if (item.hijos && item.hijos.length > 0) {
        item.hijos = item.hijos.map((h) => deactiveChilds(h, checked))
        item.pendiente = calculoPendiente(item.hijos)
    }
    return item
}

export function last_instance_opened(state = [], action) {
    switch (action.type) {
        case actions.INSTANCE_FETCH_DATA:
            if (action.items && action.items.proceso) {
                const arr = [...state]
                const index = arr.map((i) => i.id).indexOf(action.items.id)
                if (index >= 0) {
                    arr.splice(index, 1)
                } else if (arr.length >= 4) {
                    arr.splice(0, 1)
                }
                const info = {
                    family: action.items.proceso.familia,
                    code_family: action.items.proceso.idFamilia,
                    code: action.items.proceso.codigo,
                    name: action.items.proceso.nombre,
                    id: action.items.id,
                }
                arr.push(info)
                return arr
            } else {
                return state
            }
        case actions.EXECUTE_INSTANCE_TRANSITION:
            if (action.id) {
                const arr = [...state]
                const index = arr.map((i) => i.id).indexOf(action.id)
                if (index >= 0) {
                    arr.splice(index, 1)
                }
                return arr
            } else {
                return state
            }
        default:
            return state
    }
}

export function token_instance(state = 0, action) {
    switch (action.type) {
        case actions.INSTANCE_FETCH_DATA:
            return action.items ? action.items.token || 0 : 0
        default:
            return state
    }
}

export function instance(state = {}, action) {
    switch (action.type) {
        case actions.INSTANCE_FETCH_DATA:
            if (!!action.items && !!action.items.tabs) {
                let {
                    metadatosentidades,
                    condicionados,
                    reglas,
                    tabs,
                    catalogos,
                    entidades,
                    estados,
                    id,
                    proceso,
                    transiciones,
                    instancia,
                    tags,
                    clasificadores,
                } = action.items
                tabs = HideOld(condicionados, tabs, true, action.user)
                tabs = RunRules(reglas, tabs, action.user)
                tabs = tabs.map((t) => {
                    t.pendiente = calculoPendienteRecursivo(t.hijos)
                    return t
                })
                instancia.pendiente = calculoPendiente(tabs)
                instancia.puntosposibles = tabs.reduce((total, hijo) => {
                    if (!hijo.desactivado && !hijo.oculto) {
                        total += hijo.puntuacionposible
                    }
                    return total
                }, 0)
                instancia.puntosefectivos = tabs.reduce((total, hijo) => {
                    if (!hijo.desactivado && !hijo.oculto) {
                        total += hijo.puntuacionefectiva
                    }
                    return total
                }, 0)
                instancia.supendido = estaSuspenso(tabs, [2, 3])
                if (instancia.supendido) {
                    instancia.puntosefectivos = Math.min(0, instancia.puntosefectivos)
                }
                return {
                    catalogos: catalogos,
                    entidades: entidades,
                    estados: estados,
                    id: id,
                    instancia: instancia,
                    proceso: proceso,
                    reglas: reglas,
                    condicionados: condicionados,
                    tabs: tabs,
                    transiciones: transiciones,
                    metadatosentidades: metadatosentidades,
                    tags: tags,
                    clasificadores: clasificadores,
                }
            }
            return action.items

        case actions.EXECUTE_INSTANCE_TRANSITION:
            return {}

        case actions.INSTANCE_HANDLE_CHANGE:
            let tabs = [...state.tabs]
            let element = findItem(tabs, action.codigo)
            const d = new Date()
            const instancia = state.instancia
            if (element) {
                let parent = findItem(tabs, element.padre)
                let tab = undefined
                element.new = false
                element.valor = action.valor
                element.fechahoramodificacion = formatDate(d) + ' ' + formatTime(d)
                element.usuariomodificacion = action.user
                element.comentario = action.comment
                //element.desactivado = action.desactivado
                element.metadatos = action.metadatos
                element.medida = action.medida
                element.alertable = action.rango ? action.rango : element.alertable
                element.pendiente = calculoPendienteCampo(element)
                element = deactiveChilds(element, action.desactivado)

                if (element.alertable) {
                    switch (element.type) {
                        case types.boolean:
                        case types.action:
                            element.alertarango =
                                !!element.alertable &&
                                ((element.inverso && !!element.valor) || (!element.inverso && element.valor === false))
                            break
                        case types.number:
                        case types.real:
                        case types.auto:
                        case types.temp:
                        case types.entity:
                            element.alertarango = isOutRangeReal(element.valor, element.alertable)
                            break
                        case types.date:
                            element.alertarango = isOutRangeDate(element.valor, element.alertable)
                            break
                        case types.datetime:
                            element.alertarango = isOutRangeDateTime(element.valor, element.alertable)
                            break
                        case types.multioption:
                            element.alertarango =
                                element.valor && element.alertable && element.alertable.valores
                                    ? element.alertable.valores.reduce((result, v) => {
                                          return (
                                              result ||
                                              element.valor
                                                  .split(',')
                                                  .map((m) => m.toString())
                                                  .indexOf(v.toString()) >= 0
                                          )
                                      }, false)
                                    : false
                            break
                        case types.options:
                            element.alertarango =
                                element.valor && element.alertable && element.alertable.valores
                                    ? element.alertable.valores
                                          .map((m) => m.toString())
                                          .indexOf(element.valor.toString()) >= 0
                                    : false
                            break
                        default:
                            element.alertarango = false
                            break
                    }
                }
                tabs = HideOld(state.condicionados, tabs, true, action.user)
                tabs = RunRules(state.reglas, tabs, action.user)

                if (element.hijos && element.hijos.length > 0) {
                    element.pendiente = calculoPendiente(element.hijos)
                }
                if (action.desactivado) {
                    element.puntuacionposible = 0
                    element.puntuacionefectiva = 0
                } else {
                    if (element.tipo === 'SINO' && (element.estilo === 'SINONAC' || element.estilo === 'SINOC')) {
                        if (element.valor === false && (!element.comentario || element.comentario.length < 4)) {
                            element.puntuacionefectiva = 0
                            element.puntuacionposible = element.puntuacion
                        } else {
                            element.puntuacionefectiva = (action.percentage * element.puntuacionposible) / 100
                            element.puntuacionposible = element.puntuacion
                        }
                    } else if (
                        element.valor !== undefined &&
                        element.valor !== '' &&
                        element.valor !== null &&
                        element.valor.toString().length > 0 &&
                        element.valor.toString() !== '[]'
                    ) {
                        element.puntuacionefectiva =
                            element.alertarango && element.type !== types.multioption && element.type !== types.options
                                ? element.puntuacionResta * -1
                                : (action.percentage * element.puntuacionposible) / 100
                        element.puntuacionposible = element.puntuacion
                    } else {
                        if (element.hijos && element.hijos.length > 0) {
                            calculoPuntuacion(element, element.hijos)
                        } else {
                            element.puntuacionefectiva = 0
                            element.puntuacionposible = element.puntuacion
                        }
                    }
                }

                if (parent) {
                    const index = parent.hijos.map((h) => h.codigo).indexOf(element.codigo)
                    parent.hijos.splice(index, 1, element)
                    parent.pendiente = calculoPendiente(parent.hijos)
                    parent.alertarango = calculoAlerta(parent.hijos)
                    if (parent.tipo === 'PESTANA') {
                        parent.supendido = estaSuspenso(parent.hijos, [1, 2])
                    }
                    calculoPuntuacion(parent, parent.hijos)
                    tab = findItem(tabs, parent.padre)
                } else {
                    parent = element
                }

                if (tab) {
                    const index = tab.hijos.map((h) => h.codigo).indexOf(parent.codigo)
                    tab.pendiente = calculoPendiente(tab.hijos)
                    tab.alertarango = calculoAlerta(tab.hijos)
                    if (tab.tipo === 'PESTANA') {
                        tab.supendido = estaSuspenso(tab.hijos, [1, 2])
                    }
                    tab.hijos.splice(index, 1, parent)
                    calculoPuntuacion(tab, tab.hijos)
                } else {
                    tab = parent
                }
                const index = tabs.map((h) => h.codigo).indexOf(tab.codigo)
                tabs.splice(index, 1, tab)
                instancia.supendido = estaSuspenso(tabs, [2, 3])
                instancia.pendiente = calculoPendiente(tabs)
                instancia.puntosposibles = tabs.reduce((total, hijo) => {
                    if (!hijo.desactivado && !hijo.oculto) {
                        total += hijo.puntuacionposible
                    }
                    return total
                }, 0)
                instancia.puntosefectivos = tabs.reduce((total, hijo) => {
                    if (!hijo.desactivado && !hijo.oculto) {
                        total += hijo.puntuacionefectiva
                    }
                    return total
                }, 0)
                if (instancia.supendido) {
                    instancia.puntosefectivos = Math.min(0, instancia.puntosefectivos)
                }
            }
            return {
                catalogos: state.catalogos,
                entidades: state.entidades,
                estados: state.estados,
                id: state.id,
                instancia: instancia,
                proceso: state.proceso,
                reglas: state.reglas,
                condicionados: state.condicionados,
                tabs: tabs,
                tags: state.tags,
                transiciones: state.transiciones,
                metadatosentidades: state.metadatosentidades,
                clasificadores: state.clasificadores,
            }
        default:
            return state
    }
}

export function instanceHandleChange(state = [], action) {
    switch (action.type) {
        case actions.INSTANCE_HANDLE_CHANGE:
            let arr = [...state]
            const index = arr.findIndex((f) => f.codigo === action.codigo)
            const d = new Date()
            const field = {
                codigo: action.codigo,
                valor: action.valor,
                comentario: action.comment,
                desactivado: action.desactivado,
                fechahoramodificacion: formatDate(d) + ' ' + formatTime(d),
                metadatos: action.metadatos,
                medida: action.medida,
                rango: action.rango,
            }
            if (index >= 0) {
                arr[index] = field
            } else {
                arr.push(field)
            }
            return arr
        case actions.INSTANCE_SAVE_ATTACHED:
            const { attached } = action
            let list = [...state]
            const j = list.findIndex((f) => f.codigo === attached.field)
            if (j >= 0) {
                const field = list[j]
                if (field && field.metadatos && field.metadatos.adjuntos) {
                    const i = field.metadatos.adjuntos.findIndex((f) => f.base === attached.originalBase)
                    if (i >= 0) {
                        field.metadatos.adjuntos[i].base = attached.base
                        field.metadatos.adjuntos[i].link = attached.link
                        field.metadatos.adjuntos[i].mime = attached.mime
                        field.metadatos.adjuntos[i].nombre = attached.nombre
                        field.metadatos.adjuntos[i].thumbnail = attached.thumbnail
                    }
                    list[j] = field
                }
            }
            return [...list]
        case actions.CLEAR_INSTANCE_VALUES:
            return []
        default:
            return state
    }
}

export function instanceInExecution(state = false, action) {
    switch (action.type) {
        case actions.EXECUTING_INSTANCE_TRANSITION:
            return action.inExecution
        default:
            return state
    }
}

export function instanceExecuteSuccess(state = false, action) {
    switch (action.type) {
        case actions.EXECUTE_INSTANCE_TRANSITION:
            return action.success
        default:
            return state
    }
}

export function instanceKeepAttached(state = [], action) {
    switch (action.type) {
        case actions.INSTANCE_KEEP_ATTACHED:
            const info = {
                instanceid: action.instanceid,
                field: action.field,
                data: action.data,
                name: action.name,
                type: action.mime,
                base: action.base,
                attachedFile: action.attachedFile,
                user: action.user,
            }
            const temp = [...state]
            temp.push(info)
            return temp

        case actions.INSTANCE_SAVE_ATTACHED:
            const { attached } = action
            const index = state.map((m) => m.base).indexOf(attached.originalBase)
            const arr = [...state]
            if (index >= 0) {
                arr.splice(index, 1)
            }
            return arr
        default:
            return state
    }
}

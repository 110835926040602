import * as types from 'const/action.types'

export function message(state = {}, action) {
    switch (action.type) {
        case types.LOAD_MESSAGE_SUCCESS:
            return action.data
        case types.MESSAGES_EVT_CMNT_ADD:
        case types.COMMUNICATIONS_EVT_COMMT_ADD:
            if (state.idComunicacion === action.data.idComunicacion) {
                const x = state.comentarios.map((i) => i.id).indexOf(action.data.id)
                if (x < 0) {
                    state.comentarios.push(action.data)
                }
                return JSON.parse(JSON.stringify(state))
            }
            return state
        default:
            return state
    }
}

export function messages(state = [], action) {
    switch (action.type) {
        case types.MESSAGES_SUCCESS:
            return action.data
        case types.MESSAGES_EVT_COM_ADD:
            const messages = [...state]
            const i = messages.map((i) => i.id).indexOf(action.data.id)
            if (i >= 0) {
                messages.splice(i, 1)
            }
            messages.push(action.data)
            return messages
        case types.COMMUNICATIONS_EVT_COMMT_ADD:
            return state
        default:
            return state
    }
}

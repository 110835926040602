import * as types from 'const/action.types'

export default (state = true, action) => {
    switch (action.type) {
        case types.SET_WARNING_READED:
            return action.value
        default:
            return state
    }
}

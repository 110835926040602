import * as types from 'const/action.types'

export function tags(state = [], action) {
    switch (action.type) {
        case types.TAGS_SUCCESS:
            return action.data
        default:
            return state
    }
}

export function product_tags(state = [], action) {
    switch (action.type) {
        case types.PRODUCTS_TAGS_SUCCESS:
            return action.data
        default:
            return state
    }
}

export function product_tags_list(state = [], action) {
    switch (action.type) {
        case types.PRODUCTS_TAGS_LISTS_SUCCESS:
            return action.data
        default:
            return state
    }
}

export function product_tag_info(state = {}, action) {
    switch (action.type) {
        case types.PRODUCT_TAG_INFO_SUCCESS:
            return action.data
        default:
            return state
    }
}

export function catalog_tags(state = [], action) {
    switch (action.type) {
        case types.CATALOGS_TAGS_SUCCESS:
            return action.data
        default:
            return state
    }
}

export function catalog_tag_info(state = {}, action) {
    switch (action.type) {
        case types.CATALOG_TAG_INFO_SUCCESS:
            return action.data
        default:
            return state
    }
}

export function metadata_product_tag(state = [], action) {
    switch (action.type) {
        case types.METADATA_PRODUCTS_TAG_LISTS_SUCCESS:
            return action.data
        default:
            return state
    }
}

export function intervals(state = [], action) {
    switch (action.type) {
        case types.INTERVALS_SUCCESS:
            return action.data
        default:
            return state
    }
}

import * as types from 'const/action.types'
import { del, get, post, put } from 'api/_basic'
import { setError, setLoading } from './application.actions'

export function loadCommunications(runfilter, filter) {
    return async (dispatch) => {
        dispatch(setError(false, ''))
        dispatch(setLoading(true))
        const { saved, pending, urgent, from, to, selectedlocals, selectedroles } = filter

        const text_filter = runfilter
            ? '?filtro=true' +
              (pending ? '&pendientes=true' : '') +
              (saved ? '&archivadas=true' : '') +
              (urgent ? '&prioridad=true' : '') +
              (from ? `&fechaIni=${from}` : '') +
              (to ? `&fechaFin=${to}` : '') +
              (selectedlocals ? `&idLocal=${selectedlocals}` : '') +
              (selectedroles ? `&idRol=${selectedroles}` : '')
            : ''
        return get(`${process.env.REACT_APP_API_COMMUNICATIONS}${text_filter}`)
            .then((response) => {
                dispatch(setLoading(false))
                if (response.error !== undefined) {
                    throw Error(response)
                }
                return response
            })
            .then((items) => {
                dispatch(setError(false, ''))
                dispatch(loadCommunicationsSuccess(items))
            })
            .catch((error) => {
                dispatch(setLoading(false))
                dispatch(setError(error.type ? error.type : true, error.message))
            })
    }
}

export function addCommunication(communication) {
    return async (dispatch) => {
        dispatch(setLoading(true))
        return post(`${process.env.REACT_APP_API_COMMUNICATIONS}`, communication)
            .then((response) => {
                dispatch(setLoading(false))
                if (response.error !== undefined) {
                    throw Error(response)
                }
                return response
            })
            .then((item) => {
                dispatch(setError(false, ''))
                dispatch(loadCommunicationSuccess(item))
            })
            .catch((error) => {
                dispatch(setLoading(false))
                dispatch(setError(error.type ? error.type : true, error.message))
            })
    }
}

export function editCommunication(communication, avoidloading = false) {
    return async (dispatch) => {
        if (!avoidloading) dispatch(setLoading(true))
        return put(`${process.env.REACT_APP_API_COMMUNICATIONS}/${communication.id}`, communication)
            .then((response) => {
                dispatch(setLoading(false))
                if (response.error !== undefined) {
                    throw Error(response)
                }
                return response
            })
            .catch((error) => {
                dispatch(setLoading(false))
                console.error(error)
                throw error
            })
    }
}

export function sendCommunication(communication) {
    return async (dispatch) => {
        dispatch(setLoading(true))
        return put(`${process.env.REACT_APP_API_COMMUNICATIONS}/${communication.id}/enviar`, communication)
            .then((response) => {
                dispatch(setLoading(false))
                if (response.error !== undefined) {
                    throw Error(response)
                }
                return response
            })
            .catch((error) => {
                dispatch(setLoading(false))
                console.error(error)
                throw error
            })
    }
}

export function sendComment(comment) {
    return async (dispatch) => {
        dispatch(setLoading(true))
        return post(`${process.env.REACT_APP_API_COMMUNICATIONS}/${comment.id}/comentario`, comment)
            .then((response) => {
                dispatch(setLoading(false))
                if (response.error !== undefined) {
                    throw Error(response)
                }
                return response
            })
            .catch((error) => {
                dispatch(setLoading(false))
                console.error(error)
                throw error
            })
    }
}

export function saveCommunication(communication) {
    return async (dispatch) => {
        dispatch(setLoading(true))
        return put(`${process.env.REACT_APP_API_COMMUNICATIONS}/${communication.id}/archivar`, communication)
            .then((response) => {
                if (response.error !== undefined) {
                    throw Error(response)
                }
                dispatch(setLoading(false))
                return true
            })
            .catch((error) => {
                dispatch(setLoading(false))
                throw error
            })
    }
}

export function deleteCommunication(communication) {
    return async (dispatch) => {
        dispatch(setLoading(true))
        return del(`${process.env.REACT_APP_API_COMMUNICATIONS}/${communication}`)
            .then((response) => {
                if (response.error !== undefined) {
                    throw Error(response)
                }
                dispatch(setLoading(false))
                return true
            })
            .catch((error) => {
                dispatch(setLoading(false))
                console.error(error)
                throw error
            })
    }
}

export function deleteCommunicationAttached(communication, id) {
    return async (dispatch) => {
        dispatch(setLoading(true))
        return del(`${process.env.REACT_APP_API_COMMUNICATIONS}/${communication}/adjunto/${id}`)
            .then((response) => {
                if (response.error !== undefined) {
                    throw Error(response)
                }
                dispatch(setLoading(false))
                return true
            })
            .catch((error) => {
                dispatch(setLoading(false))
                console.error(error)
                throw error
            })
    }
}

export function loadInfoCommunication(communication) {
    return async function(dispatch) {
        dispatch(setError(false, ''))
        dispatch(setLoading(true))
        return get(`${process.env.REACT_APP_API_COMMUNICATIONS}/${communication}`)
            .then((response) => {
                dispatch(setLoading(false))
                if (response.error !== undefined) {
                    throw Error(response)
                }
                return response
            })
            .then((item) => {
                dispatch(setError(false, ''))
                dispatch(loadCommunicationSuccess(item))
            })
            .catch((error) => {
                dispatch(setError(error.type ? error.type : true, error.message))
            })
    }
}

export function pendingCommunications() {
    return async function(dispatch) {
        return get(`${process.env.REACT_APP_API_COMMUNICATIONS_PENDING}`)
            .then((response) => {
                return response
            })
            .then((item) => {
                dispatch(pendientes(item.mensajesAviso, item.comunicacionesAviso))
            })
            .catch((error) => {})
    }
}

export function crearComentario(data) {
    return {
        type: types.COMMUNICATIONS_EVT_COMMT_ADD,
        data,
    }
}

export function setFilter(data) {
    return {
        type: types.SET_FILTER_COMMUNICATION_SUCCESS,
        data,
    }
}

export function pendientes(mensajes, communiaciones) {
    return {
        type: types.PENDING_COMMUNICATION_SUCCESS,
        mensajes,
        communiaciones,
    }
}

export function crearComunicacion(data) {
    return {
        type: types.COMMUNICATIONS_EVT_COM_ADD,
        data,
    }
}

export function editComunicacion(data) {
    return {
        type: types.COMMUNICATIONS_EVT_COM_ADD,
        data,
    }
}

export function markComunicacionAsReaded(data) {
    return {
        type: types.COMMUNICATIONS_EVT_COM_ADD,
        data,
    }
}

export function borrarComunicacion(data) {
    return {
        type: types.COMMUNICATIONS_EVT_COM_DELE,
        data,
    }
}

export function loadCommunicationSuccess(data) {
    return {
        type: types.LOAD_COMMUNICATION_SUCCESS,
        data,
    }
}

export function loadCommunicationsSuccess(data) {
    return {
        type: types.COMMUNICATIONS_SUCCESS,
        data,
    }
}

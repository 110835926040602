import { createTheme } from "@mui/material/styles"

const green = "#228b22"
const white = "#ffffff"
const red = "#ed143d"
const grey = '#78909c'
const greylight = '#ebebeb'

// A custom theme for this app
const theme = createTheme({
    typography: {
        useNextVariants: true,
        fontFamily: "'Barlow', sans-serif;",
        textTransform: "initial"
    },
    palette: {
        background: {
            default: "#fdfffe"
        },
        primary: {
            light: green,
            main: green,
            dark: green,
            contrastText: white
        },
        secondary: {
            light: greylight,
            main: grey
        },
        error: {
            light: red,
            main: red,
            dark: red,
            contrastText: white
        }
    },
    shape: {
        borderRadius: 6
    },
    overrides: {
        MuiCssBaseline: {
            "@global": {
                a: {
                    color: green,
                    textDecoration: "none"
                }
            }
        }
    }
});

export default theme;

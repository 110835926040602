import * as types from 'const/action.types'
import { del, get, put } from 'api/_basic'

export function loadAlert(user) {
    return async (dispatch) => {
        return get(`${process.env.REACT_APP_API_ALERT}/porusuario/${user}`)
            .then((response) => {
                if (response.error !== undefined) {
                    throw Error(response)
                }
                return response
            })
            .then((items) => {
                dispatch(loadAlertSuccess(items))
            })
    }
}

export function dismiss(id) {
    return async (dispatch) => {
        return put(`${process.env.REACT_APP_API_ALERT}/${id}/dismiss`, {})
            .then((response) => {
                if (response.error !== undefined) {
                    throw Error(response)
                }
                return response
            })
            .then((items) => {
                dispatch(alertDismissSuccess(id))
                return true
            })
            .catch((error) => {
                console.error(error)
                throw error
            })
    }
}

export function deleteAlert(id) {
    return async (dispatch) => {
        return del(`${process.env.REACT_APP_API_ALERT}/${id}`)
            .then((response) => {
                if (response.error !== undefined) {
                    throw Error(response)
                }
                return response
            })
            .then((items) => {
                dispatch(alertDeleteSuccess(id))
                return true
            })
            .catch((error) => {
                console.error(error)
                throw error
            })
    }
}

export function alertDeleteSuccess(id) {
    return {
        type: types.ALERTS_DELETE_SUCCESS,
        id,
    }
}

export function alertDismissSuccess(id) {
    return {
        type: types.ALERTS_DISMISS_SUCCESS,
        id,
    }
}

export function loadAlertSuccess(data) {
    return {
        type: types.LOAD_ALERTS_SUCCESS,
        data,
    }
}

import * as types from 'const/action.types'

export function manualProcess(state = [], action) {
    switch (action.type) {
        case types.MANUAl_PROCESS_FETCH_DATA:
            return action.items
        default:
            return state
    }
}

import * as types from 'const/action.types'

export function traceability(state = [], action) {
    switch (action.type) {
        case types.LOAD_TRACEABILITY_SUCCESS:
            return action.data
        default:
            return state
    }
}

export function preparation(state = {}, action) {
    switch (action.type) {
        case types.LOAD_PREPARATION_SUCCESS:
            return action.data
        default:
            return state
    }
}

export function report_traceability(state = [], action) {
    switch (action.type) {
        case types.LOAD_REPORT_TRACEABILITY_SUCCESS:
            return action.data
        default:
            return state
    }
}

export function report_traceability_info(state = [], action) {
    switch (action.type) {
        case types.LOAD_REPORT_TRACEABILITY_INFO_SUCCESS:
            return action.data
        default:
            return state
    }
}

import * as types from 'const/action.types'

export function reference(state = {}, action) {
    switch (action.type) {
        case types.LOAD_REFERENCE_SUCCESS:
            return action.data
        default:
            return state
    }
}

export function references(state = [], action) {
    switch (action.type) {
        case types.REFERENCES_SUCCESS:
            return action.data
        default:
            return state
    }
}

import * as types from 'const/action.types'

export function values(state = {}, action) {
    switch (action.type) {
        case types.REPORT_SEARCH_VALUES_FETCH_DATA:
            return action.items
        default:
            return state
    }
}

export function fields(state = [], action) {
    switch (action.type) {
        case types.REPORT_SEARCH_FIELDS_FETCH_DATA:
            return action.items
        default:
            return state
    }
}

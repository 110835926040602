import * as types from 'const/action.types'

export function report_audit(state = {}, action) {
    switch (action.type) {
        case types.LOAD_REPORT_AUDIT_SUCCESS:
            return action.data
        default:
            return state
    }
}

import * as types from 'const/action.types'

export function display(state = {}, action) {
    switch (action.type) {
        case types.LOAD_DISPLAY_SUCCESS:
            return action.data
        default:
            return state
    }
}

export function displays(state = [], action) {
    switch (action.type) {
        case types.DISPLAYS_SUCCESS:
            return action.data
        default:
            return state
    }
}

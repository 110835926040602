import * as types from 'const/action.types'

export function list(state = [], action) {
    switch (action.type) {
        case types.LOAD_LIST_SUCCESS:
            return action.data
        default:
            return state
    }
}

import * as types from 'const/action.types'

export function manage_process(state = {}, action) {
    switch (action.type) {
        case types.MANAGE_PROCESS_FETCH_DATA:
            return getFamilies(action.items)
        case types.MANAGE_PROCESS_ADD_FAMILY:
            return addFamily(state, action)
        case types.MANAGE_PROCESS_DELETE_FAMILY:
            return deleteFamily(state, action)
        case types.MANAGE_PROCESS_EDIT_FAMILY:
            return editFamily(state, action)
        case types.MANAGE_PROCESS_DELETE_PROCESS:
            return deleteProcess(state, action)
        case types.MANAGE_PROCESS_ADD_PROCESS:
            return addProcess(state, action)
        case types.MANAGE_PROCESS_EDIT_PROCESS:
            return editProcess(state, action)
        case types.MANAGE_PROCESS_DUPLICATE_PROCESS:
            return duplicateProces(state, action)
        default:
            return state
    }
}

function getFamilies(values) {
    let arr = values.reduce((arr, value) => {
        const index = arr.map((m) => m.codigo).indexOf(value.idFamilia)
        if (index >= 0) {
            arr[index].processes.push(value)
        } else {
            arr.push({
                nombre: value.familia,
                codigo: value.idFamilia,
                processes: [value],
                status: 'original',
            })
        }
        return arr
    }, [])
    return {
        families: arr,
        modified: false,
    }
}

function editFamily(state, action) {
    const obj = state
    const info = obj.families.find((f) => f.codigo === action.code)
    const arr = obj.families
    if (info !== undefined) {
        const index = arr.map((m) => m.codigo).indexOf(action.code)
        arr.splice(index, 1)
        info.nombre = action.name
        info.codigo = action.code
        if (info.status !== 'new') {
            info.status = 'edit'
        }
        arr.push(info)
    }
    return {
        families: [...arr],
        modified: true,
    }
}

function addFamily(state, action) {
    const manage_process = state
    const field = {
        nombre: action.name,
        codigo: action.code,
        processes: [],
        status: 'new',
    }
    const arr = manage_process.families
    arr.push(field)
    return {
        families: arr,
        modified: true,
    }
}

function deleteFamily(state, action) {
    const obj = state
    const family = obj.families.find((f) => f.codigo === action.code)
    const arr = obj.families
    if (family !== undefined) {
        const index = arr.map((m) => m.codigo).indexOf(action.code)
        arr.splice(index, 1)
        if (family.status !== 'new') {
            family.status = 'deleted'
            arr.push(family)
        }
    }
    return {
        families: arr,
        modified: true,
    }
}

function deleteProcess(state, action) {
    const obj = state
    const family = obj.families.find((f) => f.codigo === action.family)
    const arr = obj.families
    if (family !== undefined) {
        const index = arr.map((m) => m.codigo).indexOf(action.family)
        arr.splice(index, 1)
        if (family.status !== 'new') {
            family.status = 'edit'
        }
        const elements = family.processes
        const indexOf = elements.map((m) => m.codigo).indexOf(action.code)
        elements.splice(indexOf, 1)
        family.processes = elements
        arr.push(family)
    }
    return {
        families: arr,
        modified: true,
    }
}

function duplicateProces(state, action) {
    const obj = state
    const family = obj.families.find((f) => f.codigo === action.family)
    const arr = obj.families
    if (family !== undefined) {
        const index = arr.map((m) => m.codigo).indexOf(action.family)
        arr.splice(index, 1)
        if (family.status !== 'new') {
            family.status = 'edit'
        }
        const elements = family.processes
        elements.push(action.data)
        family.processes = elements
        arr.push(family)
    }
    return {
        families: arr,
        modified: true,
    }
}

function addProcess(state, action) {
    const obj = state
    let family = obj.families.find((f) => f.codigo === action.family)
    const arr = obj.families
    if (!family) {
        family = {
            nombre: action.data.familia,
            codigo: action.family,
            processes: [],
            status: 'new',
        }
        arr.push(family)
    }
    if (family !== undefined) {
        const index = arr.map((m) => m.codigo).indexOf(action.family)
        arr.splice(index, 1)
        if (family.status !== 'new') {
            family.status = 'edit'
        }
        const elements = family.processes
        if (action.data.nextrun && !action.data.nextrun.date) {
            const temp = action.data.nextrun
            action.data.nextrun = {
                date: temp,
            }
        }
        elements.push(action.data)
        family.processes = elements
        arr.push(family)
    }
    return {
        families: arr,
        modified: true,
    }
}

function editProcess(state, action) {
    const obj = state
    let family = obj.families.find((f) => f.codigo === action.family)
    const arr = obj.families
    if (!family) {
        family = {
            nombre: action.data.familia,
            codigo: action.family,
            processes: [],
            status: 'new',
        }
        arr.push(family)
    }
    if (family !== undefined) {
        const index = arr.map((m) => m.codigo).indexOf(action.family)
        arr.splice(index, 1)
        if (family.status !== 'new') {
            family.status = 'edit'
        }
        const elements = family.processes
        const indexOf = elements.map((m) => m.codigo).indexOf(action.old)
        if (indexOf >= 0) {
            elements.splice(indexOf, 1)
        } else {
            arr.forEach((element) => {
                const temp = element.processes.map((m) => m.codigo).indexOf(action.old)
                if (temp >= 0) {
                    element.processes.splice(temp, 1)
                }
            })
        }
        if (action.data.nextrun && !action.data.nextrun.date) {
            const temp = action.data.nextrun
            action.data.nextrun = {
                date: temp,
            }
        }
        elements.push(action.data)
        family.processes = elements
        arr.push(family)
    }
    return {
        families: arr,
        modified: true,
    }
}

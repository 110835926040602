import * as types from 'const/action.types'

export function user(state = {}, action) {
    switch (action.type) {
        case types.LOAD_USER_SUCCESS:
            return action.data
        default:
            return state
    }
}

export function users(state = [], action) {
    switch (action.type) {
        case types.USERS_SUCCESS:
            return action.data
        default:
            return state
    }
}

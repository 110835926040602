import * as types from 'const/action.types'

export function onboarding(state = {}, action) {
    switch (action.type) {
        case types.LOAD_ONBOARDING_SUCCESS:
            return action.data
        default:
            return state
    }
}

export function onboarding_permission(state = [], action) {
    switch (action.type) {
        case types.ONBOARDING_PERMISSIONS_SUCCESS:
            return action.items
        default:
            return state
    }
}

import { combineReducers } from 'redux'
import * as types from 'const/action.types'

import { setBluetooth } from './bluetooth.reducer'
import { basicInfo, sessionReducer, warnings, quotaInfo, avisosLogin } from './session.reducer'
import { user, users } from './user.reducer'
import { charts, dashboard, indicators } from './dashboard.reducer'
import { provider, providers } from './providers.reducer'
import { reference, references } from './references.reducer'
import { template, templates } from './templates.reducer'
import { client, clients } from './client.reducer'
import { display, displays } from './display.reducer'
import { machine, machines } from './machine.reducer'
import { machinesList } from './machinelist.reducer'
import { local, localInfo, locals, localsInfo } from './locals.reducer'
import { typemachine, typemachines } from './type.machine.reducer'
import { employee, employees } from './employee.reducer'
import { rol, roles, roles_app } from './roles.reducer'
import { product, products, workstation } from './product.reducer'
import { forecast, forecasts } from './forecast.reducer'
import { xef, xefReferenceTime, xeflocal } from './xhef.reducer'
import { list } from './list.reducer'
import { termometre, termometres, termometresFields } from './termometre.reducer'
import version from './version.reducer'
import {
    error,
    global_message,
    filter,
    hidden,
    showwarning,
    loading,
    numpadactive,
    proccess,
    saving,
    searchArray,
    tools,
    voicecontrol,
} from './application.reducer'
import inventory from './process.inventory.reducer'
import alert from './alert.warning.reducer'
import alertTabs from './alert.tabs.reducer'
import { alertList } from './alert.reducer'
import { instances_opened, process, processMessages, setParameters } from './api.process.reducer'
import { manualProcess } from './api.manual.process.reducer'
import { manage_process } from './api.manage.process.reducer'
import { fields, values } from './api.reports.search.reducer'
import { lottemp } from './api.reports.lot.reducer'
import { appcc } from './api.reports.appcc.reducer'
import { report_audit } from './api.report.audit.reducer'
import { report_machines } from './api.reports.machines.reducer'
import { report_employee } from './api.reports.employee.reducer'
import { report_sts } from './api.reports.sts.reducer'
import { report_save } from './api.reports.save.reducer'
import { reportDecrease, reportDecreaseLocals } from './api.reports.decrease.reducer'
import { manage_form } from './api.manage.form.reducer'
import { catalog, catalogs } from './api.catalog.reducer'
import {
    instance,
    instanceExecuteSuccess,
    instanceHandleChange,
    instanceInExecution,
    instanceKeepAttached,
    last_instance_opened,
    token_instance,
} from './api.form.reducer'
import { message, messages } from './message.reducer'
import { communication, communications, pendingMessages, setFilterCommunications } from './communication.reducer'
import { visibility } from './visibility.reducer'
import { STS } from './sts.reducer'
import { report_productivity } from './api.reports.productivity.reducer'
import { report_process, report_process_list } from './api.report.process.reducer'
import { onboarding, onboarding_permission } from './onboarding.reducer'
import { unfrozenMessagesAlert, unfrozen_forecast, unfrozen_historical, unfrozen_info } from './unfrozen.reducer'
import { dictionary, locales } from './language.reducer'
import { printer, tagPrint } from './printer.reducer'
import {
    catalog_tag_info,
    catalog_tags,
    intervals,
    metadata_product_tag,
    product_tag_info,
    product_tags,
    product_tags_list,
    tags,
} from './tags.reducer'
import { help_option, help_options } from './help.reducer'
import { recipe, recipe_elaborations, recipes } from './recipes.reducer'
import { preparation, report_traceability, report_traceability_info, traceability } from './traceability.reducer'
import { points } from './points.reducer'
import { pac } from './pac.reducer'
import { documents } from './documents.reducer'
const appReducer = combineReducers({
    xef,
    xef_reference_time: xefReferenceTime,
    xeflocal,
    process_paramters: setParameters,
    process,
    process_messages: processMessages,
    dashboard,
    dashboard_charts: charts,
    indicators,
    manual_process: manualProcess,
    manage_process,
    manage_form,
    catalogs,
    catalog,
    instance_in_execution: instanceInExecution,
    instance_execution: instanceExecuteSuccess,
    instance_changes: instanceHandleChange,
    instance_keep_attached: instanceKeepAttached,
    instance,
    instances_opened,
    last_instance_opened,
    token_instance,
    locales,
    dictionary,
    window: proccess,
    window_tools: tools,
    window_searchs: searchArray,
    window_filter: filter,
    proccess_current_window: inventory,
    version: version,
    info: basicInfo,
    avisosLogin,
    quota: quotaInfo,
    session: sessionReducer,
    users,
    user: user,
    providers,
    provider,
    references,
    reference,
    templates,
    template: template,
    report_search_is_values: values,
    report_search_is_fields: fields,
    report_decrease: reportDecrease,
    report_decrease_locals: reportDecreaseLocals,
    report_lot: lottemp,
    report_appcc: appcc,
    report_machines,
    report_sts,
    report_save: report_save,
    report_employee: report_employee,
    report_audit,
    display,
    displays,
    local,
    localInfo,
    localsInfo,
    locals,
    machine,
    machines,
    machinesList,
    employee,
    employees,
    product,
    products,
    workstation,
    forecast,
    forecasts,
    list,
    type_machine: typemachine,
    type_machines: typemachines,
    termometre,
    termometres,
    termometres_fields: termometresFields,
    client,
    clients,
    bluetooth: setBluetooth,
    alert: alert,
    alertTabs: alertTabs,
    alerts: alertList,
    communication,
    communications,
    communications_pending: pendingMessages,
    communications_filter: setFilterCommunications,
    global_message,
    messages,
    visibility,
    sts: STS,
    report_productivity,
    rol,
    roles,
    roles_app: roles_app,
    onboarding,
    onboarding_permission,
    warnings,
    loading,
    error,
    message,
    saving,
    numpadactive,
    unfrozen_forecast,
    unfrozen_info,
    unfrozen_historical,
    unfrozen_messages_alert: unfrozenMessagesAlert,
    printer,
    tagPrint,
    tags,
    product_tags,
    product_tags_list,
    product_tag_info,
    catalog_tags,
    catalog_tag_info,
    metadata_product_tag,
    intervals,
    help_options,
    help_option,
    recipes,
    recipe,
    recipe_elaborations,
    traceability,
    preparation,
    report_traceability,
    report_traceability_info,
    report_process,
    report_process_list,
    points,
    pac,
    hidden,
    showwarning,
    documents,
    voicecontrol,
})

const rootReducer = (state, action) => {
    if (action.type === types.LOG_OUT) {
        state = {
            locales: state.locales,
        }
    }
    return appReducer(state, action)
}

export default rootReducer

import * as types from 'const/action.types'

const initialState = { user: undefined, token: undefined, valid: undefined, info: undefined }

export function sessionReducer(state = initialState, action) {
    switch (action.type) {
        case types.LOG_IN_SUCCESS:
            return {
                user: action.user,
                token: action.token,
                valid: action.valid,
                info: state.info,
            }
        case types.KEEP_SESSION:
            return {
                user: state.user,
                token: state.token,
                valid: action.time,
                info: state.info,
            }
        case types.LOAD_SESSION_USER_SUCCESS:
            return {
                user: state.user,
                token: state.token,
                valid: state.valid,
                info: action.data,
            }
        case types.LOG_OUT:
            console.error('LOG  OUT')
            return {
                ...state,
                user: undefined,
                token: undefined,
                valid: undefined,
            }
        default:
            return state
    }
}

export function quotaInfo(state = [], action) {
    switch (action.type) {
        case types.LOAD_QUOTA:
            return action.data ? action.data.quotas : []
        default:
            return state
    }
}

export function basicInfo(state = {}, action) {
    switch (action.type) {
        case types.LOAD_BASIC_INFO_SECURITY:
            return {
                security: action.data,
            }
        default:
            return state
    }
}

export function avisosLogin(state = [], action) {
    switch (action.type) {
        case types.WARNINGS:
            return action.data
        default:
            return state
    }
}

export function warnings(state = false, action) {
    switch (action.type) {
        case types.TUTORIAL:
            return action.show
        default:
            return state
    }
}

export const types = {
    boolean: 'SINO',
    action: 'action',
    text: 'TEXTO',
    textfixed: 'TEXTOFIJO',
    number: 'ENTERO',
    real: 'REAL',
    photo: 'IMAGEN',
    video: 'video',
    signature: 'FIRMA',
    options: 'CATALOGO',
    checks: 'checks',
    multioption: 'multioption',
    date: 'FECHA',
    time: 'HORA',
    datetime: 'FECHAHORA',
    local: 'LOCAL',
    tab: 'PESTANA',
    file: 'ARCHIVO',
    section: 'SECCION',
    qr: 'QR',
    temp: 'TEMPERATURA',
    auto: 'auto',
    entity: 'ENTIDAD',
    gps: 'UBICACION',
}

export const languages = [
    {
        label: 'Español',
        value: 'es',
    },
    {
        label: 'English',
        value: 'en',
    },
]

export function getOrige(type) {
    switch (type) {
        case types.action:
            return 'SINO'
        case types.multioption:
            return 'CATALOGO'
        case types.auto:
            return 'TEMPERATURA'
        default:
            return type
    }
}

export function getType(type, style) {
    switch (type) {
        case types.boolean:
            if (style === 'SINONAC') return types.action
            else if (style === 'SINOC') return types.action
            else {
                return types.boolean
            }
        case types.number:
        case types.real:
            return types.real
        case types.textfixed:
            return types.textfixed
        case types.text:
            if (style === 'DESPLEGABLE') {
                return types.options
            } else {
                return types.text
            }
        case types.options:
            if (style === 'MULTIPLE' || style === 'SIMPLE' || style === 'BOTONMULTIPLE') {
                return types.multioption
            } else {
                return types.options
            }
        case types.temp:
            if (style === 'AUTOMATICO') {
                return types.auto
            } else {
                return types.temp
            }
        case types.date:
            return types.date
        case types.datetime:
            return types.datetime
        case types.time:
            return types.time
        case types.local:
            return types.local
        case types.photo:
            return types.photo
        case types.section:
            return types.section
        case types.tab:
            return types.tab
        case 'CADENA':
            return types.text
        case 'UBICACION':
            return types.gps
        default:
            return type
    }
}

import * as types from 'const/action.types'

export function proccess(
    state = {
        title: '',
        search: false,
    },
    action,
) {
    switch (action.type) {
        case types.SET_WINDOW:
            return {
                title: action.title,
                search: state.search,
            }

        case types.SET_SEARCH:
            return {
                title: state.title,
                search: action.search,
            }
        default:
            return state
    }
}

export function filter(state = {}, action) {
    switch (action.type) {
        case types.SET_FILTER:
            state[action.window] = action.filter
            return state
        default:
            return state
    }
}

export function tools(state = [], action) {
    switch (action.type) {
        case types.SET_TOOLS:
            return action.components
        default:
            return state
    }
}

export function searchArray(state = [], action) {
    switch (action.type) {
        case types.SET_SEARCH:
            return []
        case types.SET_SEARCH_ARRAY:
            return [...action.values]
        default:
            return state
    }
}

export function loading(state = false, action) {
    switch (action.type) {
        case types.IS_LOADING:
            return action.isLoading
        default:
            return state
    }
}

export function saving(state = false, action) {
    switch (action.type) {
        case types.IS_SAVING:
            return action.saving || false
        default:
            return state
    }
}

export function hidden(state = false, action) {
    switch (action.type) {
        case types.IS_HIDDEN:
            return action.bool || false
        default:
            return state
    }
}

export function showwarning(state = false, action) {
    switch (action.type) {
        case types.SHOW_WARNING:
            return action.bool || false
        default:
            return state
    }
}

export function numpadactive(state = false, action) {
    switch (action.type) {
        case types.SET_NUM_PAD:
            return action.id
        default:
            return state
    }
}

export function error(state = { hasErrored: false, error: {} }, action) {
    switch (action.type) {
        case types.SET_ERROR:
            return {
                hasErrored: action.hasErrored,
                error: action.error,
            }
        default:
            return state
    }
}

export function global_message(state = { hasErrored: false, error: {} }, action) {
    switch (action.type) {
        case types.SET_MESSAGE:
            return {
                hasErrored: action.hasErrored,
                error: action.error,
            }
        default:
            return state
    }
}

export function voicecontrol(state = false, action) {
    switch (action.type) {
        case types.SHOW_VOICE_CONTROL:
            return action.show
        default:
            return state
    }
}

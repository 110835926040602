import * as types from 'const/action.types'

export function report_save(state = {}, action) {
    switch (action.type) {
        case types.REPORT_SAVE_FETCH_DATA:
            return action.items

        case types.XEF_ADD_EVENTS:
            if (
                state &&
                state.values &&
                state.values.productosStock &&
                state.values.producciones &&
                Array.isArray(state.values.productosStock) &&
                Array.isArray(state.values.producciones)
            ) {
                let { producciones, productos, productosStock } = state.values
                action.events
                    .filter((f) => f.evento && (f.evento.startsWith('PRD_') || f.evento === 'STK_PROD'))
                    .forEach((e) => {
                        if (e.evento === 'STK_PROD' && productosStock && productosStock.length > 0) {
                            const stock = e.payload
                            let found = false
                            productosStock.forEach((i, index) => {
                                if (i && i.idProducto === stock.idProducto) {
                                    productosStock[index] = stock
                                    found = true
                                }
                            })
                            if (!found) {
                                productosStock.push(stock)
                            }
                        } else if (e.evento === 'PRD_BAJA' && producciones && producciones.length > 0) {
                            const produccion = e.payload
                            let found = false
                            producciones.forEach((i, index) => {
                                if (i && i.id === produccion.id) {
                                    found = index
                                }
                            })
                            if (found) {
                                producciones.splice(found, 1)
                            }
                        } else if (producciones && producciones.length > 0) {
                            const produccion = e.payload
                            let found = false
                            producciones.forEach((i, index) => {
                                if (i && i.id === produccion.id) {
                                    producciones[index] = produccion
                                    found = true
                                }
                            })
                            if (!found) {
                                producciones.push(produccion)
                            }
                        }
                    })
                state.values = {
                    productosStock: [...productosStock],
                    productos: productos,
                    producciones: [...producciones],
                }
                return { ...state }
            } else {
                return state
            }

        default:
            return state
    }
}

import * as types from 'const/action.types'

export function setCurrentWindow(title) {
    return {
        type: types.SET_WINDOW,
        title,
    }
}

export function setPreviousWindow() {
    return {
        type: types.SET_PREVIOUS_WINDOWS,
    }
}

export function setTools(components) {
    return {
        type: types.SET_TOOLS,
        components,
    }
}

export function setSearch(search) {
    return {
        type: types.SET_SEARCH,
        search,
    }
}

export function searchArray(values) {
    return {
        type: types.SET_SEARCH_ARRAY,
        values,
    }
}

export function setFilter(window, filter) {
    return {
        type: types.SET_FILTER,
        window,
        filter,
    }
}

export function setLoading(isLoading) {
    return {
        type: types.IS_LOADING,
        isLoading,
    }
}

export function setError(bool, message) {
    return {
        type: types.SET_ERROR,
        hasErrored: bool,
        error: message,
    }
}

export function setMessage(bool, message) {
    return {
        type: types.SET_MESSAGE,
        hasErrored: bool,
        error: message,
    }
}

export function setSaving(bool, saving) {
    return {
        type: types.IS_SAVING,
        saving,
    }
}

export function setHidden(bool) {
    return {
        type: types.IS_HIDDEN,
        bool,
    }
}

export function setShowWarning(bool) {
    return {
        type: types.SHOW_WARNING,
        bool,
    }
}

export function setNumPad(id) {
    return {
        type: types.SET_NUM_PAD,
        id,
    }
}

export function showVoiceControl(show) {
    return {
        type: types.SHOW_VOICE_CONTROL,
        show,
    }
}

import * as types from 'const/action.types'

export function machine(state = {}, action) {
    switch (action.type) {
        case types.LOAD_MACHINE_SUCCESS:
            return action.data
        default:
            return state
    }
}

export function machines(state = [], action) {
    switch (action.type) {
        case types.MACHINES_SUCCESS:
            return action.data
        case types.XEF_ADD_EVENTS:
            const arr = [].concat([...state])
            action.events
                .filter((f) => f.evento && (f.evento.startsWith('MAQ_') || f.evento.endsWith('_LIMPIEZA')))
                .forEach((e) => {
                    const maquina = e.payload
                    let found = false
                    arr.forEach((i, index) => {
                        if (i.id === maquina.id) {
                            if (e.evento.startsWith('MAQ_')) {
                                arr[index] = maquina
                                found = true
                            } else if (e.evento.endsWith('_LIMPIEZA')) {
                                if (!arr[index].metadata || typeof arr[index].metadata !== 'object') {
                                    arr[index].metadata = {}
                                }
                                arr[index].metadata.ultimalimpieza = maquina.ultimalimpieza
                                arr[index].subestado = maquina.subestado || arr[index].subestado
                            }
                        }
                    })
                    if (e.evento.startsWith('MAQ_') && !found) {
                        arr.push(maquina)
                    }
                })
            return [...arr]
        default:
            return state
    }
}

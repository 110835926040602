import * as types from 'const/action.types'

export function unfrozen_forecast(state = {}, action) {
    switch (action.type) {
        case types.UNFROZEN_FORECAST_FETCH_DATA_SUCCESS:
            return action.items
        default:
            return state
    }
}

export function unfrozen_info(state = [], action) {
    switch (action.type) {
        case types.UNFROZEN_INFO_FETCH_DATA_SUCCESS:
            return action.items
        default:
            return state
    }
}

export function unfrozen_historical(state = [], action) {
    switch (action.type) {
        case types.UNFROZEN_HISTORICAL_FETCH_DATA_SUCCESS:
            return action.items
        default:
            return state
    }
}

export function unfrozenMessagesAlert(state = [], action) {
    switch (action.type) {
        case types.UNFROZEN_MESSAGES_ALERT:
            return action.messages ? action.messages.join(' ') : ''
        default:
            return state
    }
}

import * as types from 'const/action.types'

export function forecast(state = {}, action) {
    switch (action.type) {
        case types.LOAD_FORECAST_SUCCESS:
            return action.data
        default:
            return state
    }
}

export function forecasts(state = [], action) {
    switch (action.type) {
        case types.FORECASTS_SUCCESS:
            return action.data
        default:
            return state
    }
}

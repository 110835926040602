import * as types from 'const/action.types'

export function printer(state = { type: undefined }, action) {
    switch (action.type) {
        case types.SET_PRINTER:
            return action.printer
        default:
            return state
    }
}

export function tagPrint(state = [], action) {
    switch (action.type) {
        case types.ADD_TAG_TO_PRINT:
            if (action.tag && action.tag.bytepayload) {
                if (!state.find((f) => f.id === action.tag.id)) {
                    state.push(action.tag)
                }
            }
            return [...state]
        case types.REMOVE_TAG_TO_PRINT:
            const tag = state.find((f) => f.id === action.id)
            if (tag && tag.number > 1) {
                tag.number = tag.number - 1
                return [...state.filter((f) => f.id !== action.id), tag]
            }
            return [...state.filter((f) => f.id !== action.id)]
        default:
            return state
    }
}

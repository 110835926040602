import * as types from 'const/action.types'

export function dashboard(state = [], action) {
    switch (action.type) {
        case types.DASHBOARDS_SUCCESS:
            return action.data
        default:
            return state
    }
}

export function indicators(state = [], action) {
    switch (action.type) {
        case types.LOAD_INDICATORS_SUCCESS:
            return action.data
        default:
            return state
    }
}

export function charts(state = [], action) {
    switch (action.type) {
        case types.DASHBOARDS_CURRENT_TAB:
            return action.data
        default:
            return state
    }
}

import * as types from 'const/action.types'

export function setParameters(
    state = { lastWeek: true, from: undefined, to: undefined, ended: undefined, local: undefined },
    action,
) {
    switch (action.type) {
        case types.SET_PARAMETERS_LOAD:
            return {
                lastWeek: action.lastWeek,
                from: action.from,
                to: action.to,
                ended: action.ended,
                local: action.local,
            }

        default:
            return state
    }
}

export function process(state = [], action) {
    switch (action.type) {
        case types.PROCESS_FETCH_DATA:
            return action.items
        default:
            return state
    }
}

export function instances_opened(state = [], action) {
    switch (action.type) {
        case types.INSTANCE_OPENED_FETCH_DATA:
            return action.instances
        default:
            return state
    }
}

export function processMessages(state = [], action) {
    switch (action.type) {
        case types.PROCESS_MESSAGES:
            return action.messages
        default:
            return state
    }
}

import * as types from 'const/action.types'

export function report_process(state = {}, action) {
    switch (action.type) {
        case types.LOAD_REPORT_PROCESS_SUCCESS:
            return action.data
        default:
            return state
    }
}

export function report_process_list(state = [], action) {
    switch (action.type) {
        case types.LOAD_REPORT_PROCESS_LIST:
            return action.data
        default:
            return state
    }
}

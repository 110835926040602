import * as types from 'const/action.types'

export function rol(state = {}, action) {
    switch (action.type) {
        case types.LOAD_ROLE_SUCCESS:
            return action.data
        default:
            return state
    }
}

export function roles_app(state = [], action) {
    switch (action.type) {
        case types.LOAD_APP_ROLES_SUCCESS:
            return action.data
        default:
            return state
    }
}

export function roles(state = [], action) {
    switch (action.type) {
        case types.ROLES_SUCCESS:
            return action.data
        default:
            return state
    }
}

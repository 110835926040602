import * as types from 'const/action.types'

export function alertList(state = [], action) {
    switch (action.type) {
        case types.LOAD_ALERTS_SUCCESS:
            const previousId = state.map((a) => a.id)
            const arr = [...action.data].map((a) => {
                a.isNew = previousId.indexOf(a.id) === -1
                return a
            })
            return arr
        case types.ALERTS_DISMISS_SUCCESS:
            const dismiss = [...state]
            const i = dismiss.map((f) => f.id).indexOf(action.id)
            if (i >= 0) {
                dismiss[i].fechaVisto = new Date()
            }
            return dismiss
        case types.ALERTS_DELETE_SUCCESS:
            const temp = [...state]
            const index = temp.map((f) => f.id).indexOf(action.id)
            if (index >= 0) {
                temp.splice(index, 1)
            }
            return temp
        default:
            return state
    }
}

import * as types from 'const/action.types'
import version from '../../package.json'

export default function(state = [], action) {
    switch (action.type) {
        case types.VERSION:
            return version.version
        default:
            return version.version
    }
}

import * as types from 'const/action.types'

export function help_options(state = [], action) {
    switch (action.type) {
        case types.SET_HELP_OPTIONS:
            return action.data
        default:
            return state
    }
}

export function help_option(state = [], action) {
    switch (action.type) {
        case types.SET_HELP_OPTION:
            return action.data
        case types.SET_HELP_OPTION_INFO:
            return state && state.length > 0 ? state.map((s) => (s.id === action.data.id ? action.data : s)) : state
        default:
            return state
    }
}

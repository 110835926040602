import * as types from 'const/action.types'

export function communication(state = {}, action) {
    switch (action.type) {
        case types.LOAD_COMMUNICATION_SUCCESS:
            return action.data
        case types.MESSAGES_EVT_CMNT_ADD:
        case types.COMMUNICATIONS_EVT_COMMT_ADD:
            if (state.id === action.data.idComunicacion) {
                const x = state.comentarios.map((i) => i.id).indexOf(action.data.id)
                if (x < 0) {
                    state.comentarios.push(action.data)
                }
                return JSON.parse(JSON.stringify(state))
            }
            return state
        case types.COMMUNICATIONS_EVT_COM_ADD:
            if (state.id === action.data.id) {
                return action.data
            }
            return state
        default:
            return state
    }
}

export function communications(state = [], action) {
    switch (action.type) {
        case types.COMMUNICATIONS_SUCCESS:
            return action.data
        case types.COMMUNICATIONS_EVT_COM_ADD:
            const arr = [...state]
            const index = arr.map((i) => i.id).indexOf(action.data.id)
            if (index >= 0) {
                arr.splice(index, 1)
            }
            arr.push(action.data)
            return arr
        case types.COMMUNICATIONS_EVT_COMMT_ADD:
            return state
        case types.COMMUNICATIONS_EVT_COM_DELE:
            const remove = [...state]
            const i = remove.indexOf((i) => i.id === action.data)
            if (i >= 0) {
                remove.splice(i, 1)
            }
            return remove
        default:
            return state
    }
}

export function setFilterCommunications(
    state = {
        from: '',
        to: '',
        saved: false,
        urgent: false,
        pending: false,
        selectedroles: undefined,
        selectedusers: undefined,
        selectedlocals: undefined,
    },
    action,
) {
    switch (action.type) {
        case types.SET_FILTER_COMMUNICATION_SUCCESS:
            return action.data
        default:
            return state
    }
}

export function pendingMessages(state = { mensajes: 0, communiaciones: 0 }, action) {
    switch (action.type) {
        case types.PENDING_COMMUNICATION_SUCCESS:
            return {
                mensajes: action.mensajes ? parseInt(action.mensajes, 10) : 0,
                communiaciones: action.communiaciones ? parseInt(action.communiaciones, 10) : 0,
            }
        default:
            return state
    }
}

import * as types from 'const/action.types'
import { session } from 'store/state'
import { get, put, getToken } from 'api/_basic'
import { loadCatalogs } from './api.catalog.actions'
import { pendingCommunications } from './communications.actions'
import { setError, setLoading } from './application.actions'

const validPeriod = 30

export function grantCodigo(user, password, querystring) {
    return async function(dispatch) {
        return put(`${process.env.REACT_APP_API_USER}/${user}/validate`, { password: password })
            .then((response) => {
                if (response.error !== undefined) {
                    throw Error(response.error)
                }
                return response
            })
            .then(async (loginInfo) => {
                return await getToken(
                    `${process.env.REACT_APP_API}/grantcodigo?${querystring}`,
                    'Bearer ' + loginInfo.token,
                )
                    .then((response) => {
                        if (response.error !== undefined) {
                            throw Error(response.error)
                        }
                        return response
                    })
                    .then((response) => {
                        return response
                    })
                    .catch((error) => {
                        console.error(error)
                        throw error
                    })
            })
            .catch((error) => {
                console.error(error)
                throw error
            })
    }
}

export function loginUser(user, password) {
    return async function(dispatch) {
        return put(`${process.env.REACT_APP_API_USER}/${user}/validate`, { password: password })
            .then((response) => {
                if (response.error !== undefined) {
                    throw Error(response.error)
                }
                return response
            })
            .then(async (loginInfo) => {
                const now = new Date()
                dispatch(loginSuccess(user, 'Bearer ' + loginInfo.token, new Date(now.getTime() + validPeriod * 60000)))
                dispatch(setTutorial(!loginInfo.ultimologin))
                if (loginInfo.avisos && loginInfo.avisos.length > 0) {
                    dispatch(setWarnings(loginInfo.avisos))
                }
                return true
            })
            .catch((error) => {
                console.error(error)
                throw error
            })
    }
}

export function loadQuota() {
    return async function(dispatch) {
        get(`${process.env.REACT_APP_API}/quota`)
            .then((response) => {
                if (response.error !== undefined) {
                    throw Error(response)
                }
                return response
            })
            .then((item) => {
                dispatch(loadQuotaSucces(item))
            })
            .catch((error) => {
                dispatch(setLoading(false))
                console.error(error)
                throw error
            })
    }
}

export function loadInfoUser(user) {
    return async function(dispatch) {
        dispatch(loadBasicInfo())
        dispatch(pendingCommunications())
        dispatch(loadInfo(user))
        return true
    }
}

export function changeActiveClient(client) {
    return async function(dispatch) {
        return put(`${process.env.REACT_APP_API_CLIENT}/${client}/seleccionar`)
            .then((response) => {
                if (response.error !== undefined) {
                    throw Error(response.error)
                }
                return response
            })
            .catch((error) => {
                console.error(error)
                throw error
            })
    }
}

export function resetPassword(user) {
    return async function(dispatch) {
        return put(`${process.env.REACT_APP_API_USER}/${user}/resetpassword`, {})
            .then((response) => {
                if (response.error !== undefined) {
                    throw Error(response.error)
                }
                return response.success
            })
            .catch((error) => {
                console.error(error)
                throw error
            })
    }
}

export function recoveryPassword(password, token) {
    return async function(dispatch) {
        return put(`${process.env.REACT_APP_API_USER}doreset`, {
            password: password,
            token: token,
        })
            .then((response) => {
                if (response.error !== undefined) {
                    throw Error(response.error)
                }
                return response.success
            })
            .catch((error) => {
                console.error(error)
                throw error
            })
    }
}

export function loadInfo(user) {
    return async function(dispatch) {
        dispatch(setError(false, ''))
        dispatch(setLoading(true))
        return get(`${process.env.REACT_APP_API_USER}/${user}`)
            .then((response) => {
                dispatch(setLoading(false))
                if (response.error !== undefined) {
                    throw Error(response)
                }
                return response
            })
            .then((item) => {
                dispatch(setError(false, ''))
                dispatch(sessionUserSuccess(item))
            })
            .catch((error) => {
                dispatch(setError(error.type ? error.type : true, error.message))
            })
    }
}

export function editUser(user) {
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            return put(`${process.env.REACT_APP_API_USER}/${user.login}`, user)
                .then((response) => {
                    if (response.error !== undefined) {
                        throw Error(response)
                    }
                    dispatch(setLoading(false))
                    dispatch(sessionUserSuccess(user))
                    return true
                })
                .catch((error) => {
                    dispatch(setLoading(false))
                    dispatch(setError(error.type ? error.type : true, error.message))
                })
        } catch (error) {
            console.error(error)
            throw error
        }
    }
}

export function keepSession() {
    return async function(dispatch) {
        return put(`${process.env.REACT_APP_API_USER}/${session().user}/ping`)
            .then((response) => {
                if (response.error !== undefined) {
                    throw Error(response.error)
                }
                const now = new Date()
                dispatch(updateValidTime(new Date(now.getTime() + validPeriod * 120000)))
            })
            .catch((error) => {
                console.error(error)
                throw error
            })
    }
}

export function setZone(user, tz) {
    return async (dispatch) => {
        dispatch(setLoading(true))
        return put(`${process.env.REACT_APP_API_USER}/${user}`, { tz: tz })
            .then((response) => {
                if (response.error !== undefined) {
                    throw Error(response)
                }
                return response
            })
            .then((item) => {
                dispatch(setError(false, ''))
                dispatch(setLoading(false))
                dispatch(sessionUserSuccess(item))
            })
            .catch((error) => {
                dispatch(setLoading(false))
                console.error(error)
                throw error
            })
    }
}

export function logOutUser() {
    return async function(dispatch) {
        dispatch(logOut())
    }
}

function getNodeInfo(node, arr) {
    const tree = Object.keys(node).map(async (i) => {
        const info = arr.find((f) => f.id === parseInt(i.toString(), 10))
        return {
            collapsed: false,
            id: i,
            name: info.nombrecorto,
            path: info.nombre,
            children: node[i] !== true ? await getNodeInfo(node[i], arr) : [],
            leaf: node[i] === true,
        }
    })

    const result = Promise.all(tree).then((complete) => {
        return complete
    })
    return result
}

export function loadBasicInfo() {
    return async function(dispatch) {
        const p1 = new Promise((resolve, reject) => {
            resolve(get(process.env.REACT_APP_API_SECURITY))
        })
        const p3 = new Promise((resolve, reject) => {
            resolve(get(`${process.env.REACT_APP_API_DISPLAYCLASSES}`))
        })

        return Promise.all([p1, p3]).then(async (values) => {
            const data = values[0]
            const classes = values[1]

            const paths = data.jerarquias.map((m) => m.nombre)
            const locals = data.jerarquias.map((l) => {
                const elements = paths.find((f) => f.includes(l.nombre + ' >') && f !== l.nombre)
                return {
                    id: l.id,
                    name: l.nombrecorto,
                    path: l.nombre,
                    leaf: elements ? false : true,
                }
            })

            const users = data.usuarios.map((u) => {
                return {
                    id: parseInt(u.id, 10),
                    name: u.nombre,
                    type: 'user',
                    destinatario: u.destinatario,
                }
            })

            const roles = data.roles.map((u) => {
                return {
                    id: parseInt(u.id, 10),
                    name: u.nombre,
                    type: 'rol',
                    destinatario: u.destinatario,
                    nivel: u.nivel,
                }
            })

            const elements = await getNodeInfo(data.jerarquiasarbol, data.jerarquias)
            const total = Object.keys(data.rolesusuario).map(function(i) {
                return data.rolesusuario[i]
            })
            const security = {
                hierarchies:
                    elements.length === 1
                        ? elements[0]
                        : {
                              code: 0,
                              name: undefined,
                              children: elements,
                          },
                clients: data.clientes,
                locals: locals,
                users: users,
                roles: roles,
                classes: classes,
                destinatarios: [].concat(users).concat(roles),
                suggestions: users.concat(roles),
                localPermits: data.permisos,
                globalPermits: data.rolesglobales,
                nivelUsuario: data.nivelUsuario,
                totalPermits: total,
                licencias: data.licencias,
            }
            dispatch(loadCatalogs())
            dispatch(loadSecuritySucces(security))
            return security
        })
    }
}

export function logOut() {
    return { type: types.LOG_OUT }
}

export function loadQuotaSucces(data) {
    return {
        type: types.LOAD_QUOTA,
        data,
    }
}

export function loadSecuritySucces(data) {
    return {
        type: types.LOAD_BASIC_INFO_SECURITY,
        data,
    }
}

export function sessionUserSuccess(data) {
    return {
        type: types.LOAD_SESSION_USER_SUCCESS,
        data,
    }
}

export function setTutorial(bool) {
    return {
        type: types.TUTORIAL,
        show: bool,
    }
}

export function updateValidTime(time) {
    return {
        type: types.KEEP_SESSION,
        time,
    }
}

export function loginSuccess(user, token, valid) {
    return {
        type: types.LOG_IN_SUCCESS,
        user,
        token,
        valid,
    }
}

export function closeWarnings(data, lastIndex) {
    return async function(dispatch) {
        return put(
            `${process.env.REACT_APP_API}/aviso`,
            data.map((d, index) => {
                return { id: d.id, visionado: index < lastIndex }
            }),
        )
            .then((response) => {
                if (response.error !== undefined) {
                    throw Error(response)
                }
                return response
            })
            .then((item) => {
                dispatch(setWarnings([]))
            })
            .catch((error) => {
                dispatch(setError(error.type ? error.type : true, error.message))
            })
    }
}

export function setWarnings(warnings) {
    return {
        type: types.WARNINGS,
        data: warnings,
    }
}

import React from 'react';
import { FormattedMessage } from 'react-intl';

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

class SimpleSnackbar extends React.Component {
    color = (variant) => {
        switch (variant) {
            case 'success':
                return 'bg_primary';
            case 'error':
                return 'bg_lipstick';
            case 'warning':
                return 'bg_golden_yellow';
            case 'info':
                return 'bg_primary';
            default:
                return 'bg_golden_yellow';
        }
    };

    content = (variant, text, id) => {
        let Icon = variantIcon[variant ? variant : 'error'];
        const info = text && (typeof text === 'string')
                        ? <div className="flex_full_column info_dynamic_text" dangerouslySetInnerHTML={{__html: text }}/> 
                        : id 
                            ? <FormattedMessage id={id} /> 
                            : text;
        Icon = Icon ? Icon : ErrorIcon;
        if(!info) return <div />;
        return (
            <div className="display_flex align_center">
                <Icon className="c_white margin_right_12" />
                <div className="flex_full_column text_align_left">
                    {
                        info
                    }
                </div>
            </div>
        );
    };


    render() {
        const { message, text, variant, nonavbar, noclose } = this.props;
        const _class = this.color(variant);

        return (
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                ContentProps={{
                    classes: {
                        root: 'snackbar_root bg_transparent ' + _class,
                        message: 'snackbar_message c_white barlow_regular_message text_align_center',
                        action: 'snackbar_action'
                    },
                }}
                className={nonavbar ? '' : 'bottom_resposible'}
                open={this.props.open === true}
                autoHideDuration={100000}
                onClose={this.props.onClose}
                message={this.content(variant, text, message)}
                action={noclose ? []: [
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={this.props.onClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            />
        );
    }
}

export default SimpleSnackbar;

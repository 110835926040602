import React, { Suspense, Component } from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Detector } from 'react-detect-offline'

import { createBrowserHistory } from 'history'

import * as actionsSession from 'actions/session.actions'
import * as actionsAlert from 'actions/alert.actions'
import Loading from './components/dialogs/loading'

const rotate = React.lazy(() => import('images/iflares-icons-420x420-02.svg'))
const Home = React.lazy(() => import('pages/Home'))

const Login = React.lazy(() => import('pages/Login'))
const ResetPassword = React.lazy(() => import('pages/knowhow/ResetPassword'))
const RecoveryPassword = React.lazy(() => import('pages/knowhow/RecoveryPassword'))
const Pin = React.lazy(() => import('pages/knowhow/Pin'))
const NotFound = React.lazy(() => import('pages/NotFound'))
const Logout = React.lazy(() => import('pages/Logout'))
const Session = React.lazy(() => import('./components/labels/Session'))

const Dashboad = React.lazy(() => import('pages/dashboard/Index'))
const Checks = React.lazy(() => import('pages/checks/Index'))
const Alerts = React.lazy(() => import('pages/alerts/Index'))
const Forms = React.lazy(() => import('pages/forms/Index'))

const Manage = React.lazy(() => import('pages/manage/Index'))
const ManageChecks = React.lazy(() => import('pages/manage/checks/Index'))
const ManageCheckForms = React.lazy(() => import('pages/manage/checks/Main'))
const Employees = React.lazy(() => import('pages/manage/employees/Index'))
const Employee = React.lazy(() => import('pages/manage/employee/Index'))
const Users = React.lazy(() => import('pages/manage/users/Index'))
const User = React.lazy(() => import('pages/manage/user/Index'))
const Catalogs = React.lazy(() => import('pages/manage/catalogs/Index'))
const Catalog = React.lazy(() => import('pages/manage/catalog/Index'))
const Customers = React.lazy(() => import('pages/manage/customers/Index'))
const Customer = React.lazy(() => import('pages/manage/customer/Index'))
const Displays = React.lazy(() => import('pages/manage/displays/Index'))
const Display = React.lazy(() => import('pages/manage/display/Index'))
const DisplayEdit = React.lazy(() => import('pages/manage/display/Edit'))
const Termometres = React.lazy(() => import('pages/manage/termometres/Index'))
const Termometre = React.lazy(() => import('pages/manage/termometre/Index'))
const Provider = React.lazy(() => import('pages/manage/provider/Index'))
const Providers = React.lazy(() => import('pages/manage/providers/Index'))
const Reference = React.lazy(() => import('pages/manage/reference/Index'))
const References = React.lazy(() => import('pages/manage/references/Index'))
const Template = React.lazy(() => import('pages/manage/template/Index'))
const Templates = React.lazy(() => import('pages/manage/templates/Index'))
const LocalsInfo = React.lazy(() => import('pages/manage/locals/Index'))
const LocalInfo = React.lazy(() => import('pages/manage/local/Index'))
const Roles = React.lazy(() => import('pages/manage/roles/Index'))
const Points = React.lazy(() => import('pages/manage/points/Index'))
const FieldTags = React.lazy(() => import('pages/manage/fieldtags/Index'))

const Inventory = React.lazy(() => import('pages/xef/Index'))
const Products = React.lazy(() => import('pages/xef/products/Index'))
const Recipe = React.lazy(() => import('pages/xef/recipe/Index'))
const Locals = React.lazy(() => import('pages/xef/locals/Index'))
const Local = React.lazy(() => import('pages/xef/local/Index'))

const Forecasts = React.lazy(() => import('pages/xef/forecasts/Index'))
const Forecast = React.lazy(() => import('pages/xef/forecast/Index'))

const Machines = React.lazy(() => import('pages/xef/machines/Index'))
const Machine = React.lazy(() => import('pages/xef/machine/Index'))
const Lists = React.lazy(() => import('pages/xef/catalogs/Index'))
const List = React.lazy(() => import('pages/xef/catalog/Index'))
const OuiChef = React.lazy(() => import('pages/xef/fast/Index'))
const Traceability = React.lazy(() => import('pages/manage/traceability/Index'))
const Preparation = React.lazy(() => import('pages/manage/preparation/Index'))
const Newpreparation = React.lazy(() => import('pages/manage/newpreparation/Index'))

const DisplaysCallback = React.lazy(() => import('pages/manage/displayscallback/Index'))
const Reports = React.lazy(() => import('pages/reports/Index'))
const Bi = React.lazy(() => import('pages/reports/bi/Index'))
const Search = React.lazy(() => import('pages/reports/search/Index'))
const Decrease = React.lazy(() => import('pages/reports/decrease/Index'))
const Lote = React.lazy(() => import('pages/reports/lot/Index'))
const APPCC = React.lazy(() => import('pages/reports/appcc/Index'))
const ReportEmployee = React.lazy(() => import('pages/reports/employee/Index'))
const ReportMachine = React.lazy(() => import('pages/reports/machines/Index'))
const STS = React.lazy(() => import('pages/reports/sts/Index'))
const Save = React.lazy(() => import('pages/reports/save/Index'))
const Provisioning = React.lazy(() => import('pages/reports/provisioning/Index'))
const Visibilty = React.lazy(() => import('pages/reports/visibility/Index'))
const VisibiltyMenu = React.lazy(() => import('pages/reports/visibility/Menu'))
const Productivity = React.lazy(() => import('pages/reports/productivity/Index'))
const Process = React.lazy(() => import('pages/reports/process/Index'))
const ProcessMenu = React.lazy(() => import('pages/reports/process/Menu'))
const Audit = React.lazy(() => import('pages/reports/audit/Index'))
const AuditMenu = React.lazy(() => import('pages/reports/audit/Menu'))

const Xhef = React.lazy(() => import('pages/ouixef/Index'))
const Llaollao = React.lazy(() => import('pages/ouixefmini/Index'))

const Communications = React.lazy(() => import('pages/communications/Index'))

const CleanLocal = React.lazy(() => import('pages/cleanlocal/index'))

const Onboarding = React.lazy(() => import('pages/onboarding/index'))

const MachinesList = React.lazy(() => import('pages/manage/machines/Index'))
const MachinesListInfo = React.lazy(() => import('pages/manage/machinesLocal/Index'))
const MachineInfo = React.lazy(() => import('pages/manage/machine/Index'))

const RecipeList = React.lazy(() => import('pages/manage/recipes/Index'))
const RecipeInfo = React.lazy(() => import('pages/manage/recipe/Index'))

const Translate = React.lazy(() => import('pages/translate/Index'))
const ManageTagsText = React.lazy(() => import('pages/xef/texts/Index'))
const ManageTagsProducts = React.lazy(() => import('pages/manage/tagsproducts/Index'))
const Tags = React.lazy(() => import('pages/tags/Index'))
const ProductsTags = React.lazy(() => import('pages/tags/Products'))
const Unfrozen = React.lazy(() => import('pages/xef/unfrozen/Index'))
const PDFAPPCC = React.lazy(() => import('pages/reports/exportappcc/Index'))
const Help = React.lazy(() => import('pages/help/Index'))
const Me = React.lazy(() => import('pages/me/Index'))
const Telegram = React.lazy(() => import('pages/telegram/Index'))
const QR = React.lazy(() => import('pages/qr/Index'))

const Suppliers = React.lazy(() => import('pages/suppliers/Index'))
const SuppliersMain = React.lazy(() => import('pages/suppliers/Main'))
const TraceabilityReport = React.lazy(() => import('pages/reports/traceability/Index'))

const Pac = React.lazy(() => import('pages/pac/Index'))
const Documents = React.lazy(() => import('pages/documents/Index'))

const PrivateRoute = React.lazy(() => import('./PrivateRoute'))
const ProtectRoute = React.lazy(() => import('./ProtectRoute'))
const Sse = React.lazy(() => import('./Sse'))
const Printer = React.lazy(() => import('./Printer'))

const Test = React.lazy(() => import('pages/test/Index'))
const SplashScreen = React.lazy(() => import('components/splashscreen/Index'))

const history = createBrowserHistory()

class App extends Component {
    state = {
        orientation: window.orientation,
        isFullscreenEnabled: true,
        online: true,
    }

    componentDidMount() {
        //if (process.env.NODE_ENV === 'production' && window.location.search.indexOf('callback') < 0) {
        //    this.props.logOutUser()
        //}

        window.addEventListener('storage', (e) => {
            if (e.key === 'persist:iflares') {
                if (e.newValue && e.oldValue) {
                    const oldSession = JSON.parse(e.oldValue).session
                    const oldToken = JSON.parse(oldSession).token
                    const newSession = JSON.parse(e.newValue).session
                    const newToken = JSON.parse(newSession).token
                    if (newToken !== oldToken) {
                        console.error('Reload by', oldToken, newToken)
                        window.location.reload()
                    }
                }
            }
        })
    }

    checkOffline = () => {
        clearTimeout(this.offlineTimeout)
        this.setState({ online: this.state.currentonline })
    }

    handleOnline = (online) => {
        if (online !== this.state.online) {
            this.offlineTimeout = setTimeout(this.checkOffline, 5000)
            this.setState({ currentonline: online })
        } else {
            clearTimeout(this.offlineTimeout)
        }
    }

    render() {
        const divStyle = { backgroundImage: 'url(' + rotate + ')' }
        const HOMEID = 0
        const PROCESSID = 1
        const OUICHEFID = 2
        const XEFID = 3
        const REPORTSID = 4
        const ALERTSID = 5
        const MANAGEID = 6
        const COMMUNICATIONS = 7
        const TAGS = 8

        const { warnings } = this.props
        const { online } = this.state

        return (
            <Detector
                polling={{ url: `${process.env.REACT_APP_API}/ping`, enabled: true, interval: 10000, timeout: 60000 }}
                onChange={this.handleOnline}
                render={() => (
                    <div>
                        <Suspense fallback={<Loading open opacity />}>
                            <div id="main" className="main">
                                <Router history={history}>
                                    <Switch>
                                        <PrivateRoute
                                            exact
                                            path="/"
                                            component={(props) => (
                                                <Home {...props} online={online} id={HOMEID} title={'title'}>
                                                    <Dashboad />
                                                </Home>
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/sts/:id"
                                            component={(props) => <CleanLocal id={props.match.params.id} />}
                                        />
                                        <Route
                                            exact
                                            path="/onboarding/:id"
                                            component={(props) => <Onboarding id={props.match.params.id} />}
                                        />
                                        <Route exact path="/logout" component={Logout} />
                                        <Route exact path="/user/login" component={Login} />
                                        <Route exact path="/password-reset" component={ResetPassword} />
                                        <Route
                                            exact
                                            path="/recovery/:token"
                                            component={(props) => (
                                                <RecoveryPassword {...props} param={props.match.params.token} />
                                            )}
                                        />
                                        <PrivateRoute exact path="/user/pin" component={Pin} />
                                        <PrivateRoute
                                            exact
                                            path="/qr"
                                            component={(props) => (
                                                <Home {...props} online={online} title={'title'} hide back>
                                                    <QR />
                                                </Home>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/qr/:id"
                                            component={(props) => (
                                                <Home {...props} online={online} title={'title'} hide back>
                                                    <QR id={props.match.params.id} />
                                                </Home>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/translate/:id"
                                            component={(props) => (
                                                <Home {...props} online={online} title={'translate_title'} hide back>
                                                    <Translate id={props.match.params.id} />
                                                </Home>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/traceability"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '171']} licencias={[169]}>
                                                    <Home
                                                        {...props}
                                                        online={online}
                                                        back
                                                        id={OUICHEFID}
                                                        title={'traceability_menu'}
                                                    >
                                                        <Traceability id={props.match.params.id} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/traceability/new"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '171']} licencias={[169]}>
                                                    <Home
                                                        {...props}
                                                        online={online}
                                                        back
                                                        id={OUICHEFID}
                                                        title={'traceability_menu'}
                                                    >
                                                        <Newpreparation local={props.match.params.local} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/traceability/:id"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '171']} licencias={[169]}>
                                                    <Home
                                                        {...props}
                                                        online={online}
                                                        back
                                                        id={OUICHEFID}
                                                        title={'traceability_menu'}
                                                    >
                                                        <Preparation
                                                            local={props.match.params.local}
                                                            id={props.match.params.id}
                                                        />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/traceability/new/:local/:idReferencia/:codigo"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '171']} licencias={[169]}>
                                                    <Home
                                                        {...props}
                                                        online={online}
                                                        back
                                                        id={OUICHEFID}
                                                        title={'traceability_menu'}
                                                    >
                                                        <Preparation
                                                            local={props.match.params.local}
                                                            idReferencia={props.match.params.idReferencia}
                                                            codigo={props.match.params.codigo}
                                                        />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/suppliers/"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '62']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        online={online}
                                                        id={OUICHEFID}
                                                        title={'suppliers_title'}
                                                    >
                                                        <Suppliers />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/suppliers/:local"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '62']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        online={online}
                                                        back
                                                        id={OUICHEFID}
                                                        title={'suppliers_title'}
                                                    >
                                                        <SuppliersMain local={props.match.params.local} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/ouichef/"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '58']} licencias={[154]}>
                                                    <Home
                                                        {...props}
                                                        online={online}
                                                        id={OUICHEFID}
                                                        title={'fast_title'}
                                                    >
                                                        <OuiChef />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/ouichef/:id"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '58']} licencias={[154]}>
                                                    <Home
                                                        {...props}
                                                        online={online}
                                                        id={OUICHEFID}
                                                        title={'fast_title'}
                                                    >
                                                        <Xhef id={props.match.params.id} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/llaollao/:id"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '213']} licencias={[163]}>
                                                    <Home {...props} online={online} id={OUICHEFID} hide>
                                                        <Llaollao id={props.match.params.id} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/processes/"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '57']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        online={online}
                                                        id={PROCESSID}
                                                        title={'process_title'}
                                                    >
                                                        <Checks />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/processes/:local"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '57']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        online={online}
                                                        id={PROCESSID}
                                                        title={'process_title'}
                                                        param={props.match.params.local}
                                                    >
                                                        <Checks {...props} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />

                                        <PrivateRoute
                                            exact
                                            path="/instance/:instance"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '57']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        online={online}
                                                        id={PROCESSID}
                                                        back
                                                        title={'process_title'}
                                                    >
                                                        <Forms
                                                            online={online}
                                                            param={props.match.params.instance}
                                                            params={props.match.params}
                                                        />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/instance/:instance/:form"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '57']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        online={online}
                                                        id={PROCESSID}
                                                        back
                                                        backoffline
                                                        title={'process_title'}
                                                    >
                                                        <Forms
                                                            online={online}
                                                            param={props.match.params.form}
                                                            params={props.match.params}
                                                            showForm
                                                        />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/xef/"
                                            component={(props) => (
                                                <Home {...props} online={online} id={XEFID} title={'inventory_title'}>
                                                    <Inventory />
                                                </Home>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/tags"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '167']} licencias={[166]}>
                                                    <Home {...props} online={online} id={TAGS} title={'tags_menu'}>
                                                        <Tags />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/tags/:type"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '167']} licencias={[166]}>
                                                    <Home {...props} online={online} back id={TAGS} title={'tags_menu'}>
                                                        <ProductsTags
                                                            param={props.match.params.type}
                                                            params={props.match.params}
                                                        />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/xef/forecast"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '59', '66']} licencias={[154]}>
                                                    <Home
                                                        {...props}
                                                        online={online}
                                                        id={XEFID}
                                                        title={'production_list_title'}
                                                    >
                                                        <Forecasts />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/xef/forecast/:id"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '59', '66']} licencias={[154]}>
                                                    <Home
                                                        {...props}
                                                        id={XEFID}
                                                        online={online}
                                                        back
                                                        title={'production_list_title'}
                                                    >
                                                        <Forecast id={props.match.params.id} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/xef/forecast/:id/:back"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '59', '66']} licencias={[154]}>
                                                    <Home
                                                        {...props}
                                                        id={XEFID}
                                                        online={online}
                                                        back
                                                        title={'production_list_title'}
                                                    >
                                                        <Forecast
                                                            id={props.match.params.id}
                                                            back={props.match.params.back}
                                                        />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/xef/products"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '215', '67']} licencias={[154, 163]}>
                                                    <Home
                                                        {...props}
                                                        online={online}
                                                        id={XEFID}
                                                        title={'products_title'}
                                                    >
                                                        <Products />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/xef/products/new_product"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '215', '67']} licencias={[154, 163]}>
                                                    <Home
                                                        {...props}
                                                        online={online}
                                                        id={XEFID}
                                                        back
                                                        title={'products_title'}
                                                    >
                                                        <Recipe />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/xef/products/:id"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '215', '67']} licencias={[154, 163]}>
                                                    <Home
                                                        {...props}
                                                        online={online}
                                                        id={XEFID}
                                                        back
                                                        title={'products_title'}
                                                    >
                                                        <Recipe id={props.match.params.id} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/xef/locals"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '216', '68']} licencias={[154, 163]}>
                                                    <Home
                                                        {...props}
                                                        id={XEFID}
                                                        online={online}
                                                        back
                                                        title={'manage_local_menu'}
                                                    >
                                                        <Locals />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/xef/locals/:local"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '216', '68']} licencias={[154, 163]}>
                                                    <Home
                                                        {...props}
                                                        id={XEFID}
                                                        online={online}
                                                        back
                                                        title={'manage_local_menu'}
                                                    >
                                                        <Local id={props.match.params.local} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/xef/catalogs"
                                            component={(props) => (
                                                <ProtectRoute
                                                    permisos={['20', '215', '67', '59', '168']}
                                                    licencias={[154, 163, 166]}
                                                >
                                                    <Home
                                                        {...props}
                                                        id={XEFID}
                                                        online={online}
                                                        back
                                                        title={'manage_catolog_menu'}
                                                    >
                                                        <Lists />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/xef/catalogs/:id"
                                            component={(props) => (
                                                <ProtectRoute
                                                    permisos={['20', '215', '67', '59']}
                                                    licencias={[154, 163]}
                                                >
                                                    <Home
                                                        {...props}
                                                        id={XEFID}
                                                        online={online}
                                                        back
                                                        title={'manage_catolog_menu'}
                                                    >
                                                        <List id={props.match.params.id} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/xef/unfrozen"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '165']} licencias={[154]}>
                                                    <Home
                                                        {...props}
                                                        online={online}
                                                        id={XEFID}
                                                        back
                                                        title={'unfrozen_menu'}
                                                    >
                                                        <Unfrozen />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/xef/unfrozen/:id"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '165']} licencias={[154]}>
                                                    <Home
                                                        {...props}
                                                        online={online}
                                                        id={XEFID}
                                                        back
                                                        title={'unfrozen_menu'}
                                                    >
                                                        <Unfrozen id={props.match.params.id} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/reports/"
                                            component={(props) => (
                                                <ProtectRoute
                                                    permisos={['20', '60', '82', '83', '86', '211', '214']}
                                                    licencias={[150, 154, 157, 163]}
                                                >
                                                    <Home
                                                        {...props}
                                                        id={REPORTSID}
                                                        online={online}
                                                        title={'report_title'}
                                                    >
                                                        <Reports />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/reports/traceability"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '171']} licencias={[169]}>
                                                    <Home
                                                        {...props}
                                                        online={online}
                                                        back
                                                        id={OUICHEFID}
                                                        title={'traceability_menu'}
                                                    >
                                                        <TraceabilityReport id={props.match.params.id} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/reports/productivity"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '211']} licencias={[154]}>
                                                    <Home
                                                        {...props}
                                                        id={REPORTSID}
                                                        back
                                                        online={online}
                                                        title={'productivity_menu'}
                                                    >
                                                        <Productivity />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/reports/bi"
                                            component={(props) => (
                                                <ProtectRoute permisos={['175', '176']}>
                                                    <Home
                                                        {...props}
                                                        id={REPORTSID}
                                                        back
                                                        online={online}
                                                        title={'bi_report_menu'}
                                                    >
                                                        <Bi />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/reports/bi/:id"
                                            component={(props) => (
                                                <ProtectRoute permisos={['175', '176']}>
                                                    <Home
                                                        {...props}
                                                        id={REPORTSID}
                                                        back
                                                        online={online}
                                                        title={'bi_report_menu'}
                                                    >
                                                        <Bi id={props.match.params.id} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/reports/decrease"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '211', '214']} licencias={[154, 163]}>
                                                    <Home
                                                        {...props}
                                                        id={REPORTSID}
                                                        online={online}
                                                        back
                                                        title={'manage_local_menu'}
                                                    >
                                                        <Decrease />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/reports/decrease/result"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '211', '214']} licencias={[154, 163]}>
                                                    <Home
                                                        {...props}
                                                        id={REPORTSID}
                                                        online={online}
                                                        back
                                                        title={'manage_local_menu'}
                                                    >
                                                        <Decrease result />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/reports/controlpanel"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '82']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={REPORTSID}
                                                        online={online}
                                                        back
                                                        title={'visibility_menu'}
                                                    >
                                                        <VisibiltyMenu />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/reports/controlpanel/result"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '82']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={REPORTSID}
                                                        online={online}
                                                        back
                                                        title={'visibility_menu'}
                                                    >
                                                        <Visibilty />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/reports/process"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '82']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={REPORTSID}
                                                        online={online}
                                                        back
                                                        title={'process_report_menu'}
                                                    >
                                                        <ProcessMenu />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/reports/process/result"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '82']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={REPORTSID}
                                                        online={online}
                                                        back
                                                        title={'process_report_menu'}
                                                    >
                                                        <Process />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/reports/audit"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '82']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={REPORTSID}
                                                        online={online}
                                                        back
                                                        title={'audit_report_menu'}
                                                    >
                                                        <AuditMenu />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/reports/audit/result"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '82']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={REPORTSID}
                                                        online={online}
                                                        back
                                                        title={'audit_report_menu'}
                                                    >
                                                        <Audit />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/reports/sts"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '83']} licencias={[157]}>
                                                    <Home
                                                        {...props}
                                                        id={REPORTSID}
                                                        online={online}
                                                        back
                                                        title={'report_sts_menu'}
                                                    >
                                                        <STS />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/reports/sts/result"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '83']} licencias={[157]}>
                                                    <Home
                                                        {...props}
                                                        id={REPORTSID}
                                                        online={online}
                                                        back
                                                        title={'report_sts_menu'}
                                                    >
                                                        <STS result />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/reports/provisioning"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '83']} licencias={[157]}>
                                                    <Home
                                                        {...props}
                                                        id={REPORTSID}
                                                        online={online}
                                                        back
                                                        title={'provisioning_menu'}
                                                    >
                                                        <Provisioning />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/reports/provisioning/:id"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '83']} licencias={[157]}>
                                                    <Home {...props} online={online} id={REPORTSID} back>
                                                        <Provisioning local={props.match.params.id} param={true} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/reports/save"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '211']} licencias={[157]}>
                                                    <Home
                                                        {...props}
                                                        id={REPORTSID}
                                                        online={online}
                                                        back
                                                        title={'save_menu'}
                                                    >
                                                        <Save />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/reports/save/:id"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '211']} licencias={[157]}>
                                                    <Home {...props} online={online} id={REPORTSID} back>
                                                        <Save local={props.match.params.id} param={true} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/reports/pdfappcc"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '60']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={REPORTSID}
                                                        online={online}
                                                        back
                                                        title={'manage_pdf_appcc_menu'}
                                                    >
                                                        <PDFAPPCC />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />

                                        <PrivateRoute
                                            exact
                                            path="/reports/APPCC"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '60']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={REPORTSID}
                                                        online={online}
                                                        back
                                                        title={'manage_appcc_menu'}
                                                    >
                                                        <APPCC />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/reports/APPCC/result"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '60']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={REPORTSID}
                                                        online={online}
                                                        back
                                                        title={'manage_appcc_menu'}
                                                    >
                                                        <APPCC result />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/reports/employee"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '86']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={REPORTSID}
                                                        online={online}
                                                        back
                                                        title={'report_employee_menu'}
                                                    >
                                                        <ReportEmployee />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/reports/employee/detail/:id"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '86']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={REPORTSID}
                                                        online={online}
                                                        back
                                                        title={'report_employee_menu'}
                                                    >
                                                        <ReportEmployee id={'detail'} proceso={props.match.params.id} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/reports/employee/:id"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '86']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={REPORTSID}
                                                        online={online}
                                                        back
                                                        title={'report_employee_menu'}
                                                    >
                                                        <ReportEmployee id={props.match.params.id} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/reports/machines"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '60']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={REPORTSID}
                                                        online={online}
                                                        back
                                                        title={'report_marchines'}
                                                    >
                                                        <ReportMachine />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/reports/machines/result"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '60']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={REPORTSID}
                                                        online={online}
                                                        back
                                                        title={'report_marchines'}
                                                    >
                                                        <ReportMachine result />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/reports/lot"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '60']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={REPORTSID}
                                                        online={online}
                                                        back
                                                        title={'report_search_lot_temp'}
                                                    >
                                                        <Lote />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/reports/lot/result"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '60']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={REPORTSID}
                                                        online={online}
                                                        back
                                                        title={'report_search_lot_temp'}
                                                    >
                                                        <Lote result />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/reports/search"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '60']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={REPORTSID}
                                                        online={online}
                                                        back
                                                        title={'report_search_values'}
                                                    >
                                                        <Search />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/reports/search/result"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '60']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={REPORTSID}
                                                        online={online}
                                                        back
                                                        title={'report_search_values'}
                                                    >
                                                        <Search result />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/alerts/"
                                            component={(props) => (
                                                <ProtectRoute permisos={['57']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={ALERTSID}
                                                        online={online}
                                                        title={'warning_title'}
                                                    >
                                                        <Alerts />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/"
                                            component={(props) => (
                                                <Home {...props} online={online} id={MANAGEID} title={'manage_title'}>
                                                    <Manage />
                                                </Home>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/xef/machines"
                                            component={(props) => (
                                                <ProtectRoute
                                                    permisos={['20', '202', '204', '206', '208']}
                                                    licencias={[162, 161, 160, 159]}
                                                >
                                                    <Home
                                                        {...props}
                                                        id={XEFID}
                                                        online={online}
                                                        back
                                                        title={'manage_machines_menu'}
                                                    >
                                                        <Machines />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/xef/machines/new_machine"
                                            component={(props) => (
                                                <ProtectRoute
                                                    permisos={['20', '202', '204', '206', '208']}
                                                    licencias={[162, 161, 160, 159]}
                                                >
                                                    <Home
                                                        {...props}
                                                        id={XEFID}
                                                        online={online}
                                                        back
                                                        title={'manage_machines_menu'}
                                                    >
                                                        <Machine />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/xef/machines/:machine"
                                            component={(props) => (
                                                <ProtectRoute
                                                    permisos={['20', '202', '204', '206', '208']}
                                                    licencias={[162, 161, 160, 159]}
                                                >
                                                    <Home
                                                        {...props}
                                                        id={XEFID}
                                                        online={online}
                                                        back
                                                        title={'manage_machines_menu'}
                                                    >
                                                        <Machine id={props.match.params.machine} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/user"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '64']} licencias={[152]}>
                                                    <Home
                                                        {...props}
                                                        id={MANAGEID}
                                                        online={online}
                                                        back
                                                        title={'manage_user_menu'}
                                                    >
                                                        <Users />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/user/:user"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '64']} licencias={[152]}>
                                                    <Home
                                                        {...props}
                                                        id={MANAGEID}
                                                        online={online}
                                                        back
                                                        title={'manage_user_menu'}
                                                    >
                                                        <User id={props.match.params.user} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/new_user"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '64']} licencias={[152]}>
                                                    <Home
                                                        {...props}
                                                        id={MANAGEID}
                                                        online={online}
                                                        back
                                                        title={'manage_new_user_menu'}
                                                    >
                                                        <User />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/employees"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '84']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={MANAGEID}
                                                        online={online}
                                                        back
                                                        title={'manage_employee_menu'}
                                                    >
                                                        <Employees />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/employees/:employee"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '84']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={MANAGEID}
                                                        online={online}
                                                        back
                                                        title={'manage_employee_menu'}
                                                    >
                                                        <Employee id={props.match.params.employee} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/new_employee"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '84']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={MANAGEID}
                                                        online={online}
                                                        back
                                                        title={'manage_new_employee_menu'}
                                                    >
                                                        <Employee />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/points"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '81']} licencias={[157]}>
                                                    <Home
                                                        {...props}
                                                        online={online}
                                                        id={MANAGEID}
                                                        back
                                                        title={'points_menu'}
                                                    >
                                                        <Points />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/processtags"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '62']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        online={online}
                                                        title={'form_field_name_tag_procesos'}
                                                    >
                                                        <FieldTags type="procesotag" />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/fieldtags"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '62']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        online={online}
                                                        title={'form_field_name_tag_campos'}
                                                    >
                                                        <FieldTags type="campotag" />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/locals"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '81']} licencias={[157]}>
                                                    <Home
                                                        {...props}
                                                        online={online}
                                                        id={MANAGEID}
                                                        back
                                                        title={'locals_menu'}
                                                    >
                                                        <LocalsInfo />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/locals/:local"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '81']} licencias={[157]}>
                                                    <Home
                                                        {...props}
                                                        online={online}
                                                        id={MANAGEID}
                                                        back
                                                        title={'locals_menu'}
                                                    >
                                                        <LocalInfo id={props.match.params.local} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/recipes"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '170']} licencias={[169]}>
                                                    <Home
                                                        {...props}
                                                        online={online}
                                                        id={MANAGEID}
                                                        back
                                                        title={'recipes_menu'}
                                                    >
                                                        <RecipeList />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/recipes/:recipe"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '170']} licencias={[169]}>
                                                    <Home
                                                        {...props}
                                                        online={online}
                                                        id={MANAGEID}
                                                        back
                                                        title={'recipes_menu'}
                                                    >
                                                        <RecipeInfo id={props.match.params.recipe} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/recipes/:recipe/copy"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '170']} licencias={[169]}>
                                                    <Home
                                                        {...props}
                                                        online={online}
                                                        id={MANAGEID}
                                                        back
                                                        title={'recipes_menu'}
                                                    >
                                                        <RecipeInfo id={props.match.params.recipe} copy />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/machines"
                                            component={(props) => (
                                                <ProtectRoute
                                                    permisos={['20', '202', '203', '204', '206', '208']}
                                                    licencias={[159, 160, 161, 162]}
                                                >
                                                    <Home
                                                        {...props}
                                                        online={online}
                                                        id={MANAGEID}
                                                        back
                                                        title={'machines_menu'}
                                                    >
                                                        <MachinesList />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/machines/:local"
                                            component={(props) => (
                                                <ProtectRoute
                                                    permisos={['20', '202', '203', '204', '206', '208']}
                                                    licencias={[159, 160, 161, 162]}
                                                >
                                                    <Home {...props} online={online} id={MANAGEID} back>
                                                        <MachinesListInfo id={props.match.params.local} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/machines/:local/:area/new"
                                            component={(props) => (
                                                <ProtectRoute
                                                    permisos={['20', '202', '203', '204', '206', '208']}
                                                    licencias={[159, 160, 161, 162]}
                                                >
                                                    <Home
                                                        {...props}
                                                        online={online}
                                                        id={MANAGEID}
                                                        back
                                                        title={'machines_menu'}
                                                    >
                                                        <MachineInfo
                                                            id={props.match.params.local}
                                                            area={props.match.params.area}
                                                            new={true}
                                                        />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/machines/:local/:area/:machine"
                                            component={(props) => (
                                                <ProtectRoute
                                                    permisos={['20', '202', '203', '204', '206', '208']}
                                                    licencias={[159, 160, 161, 162]}
                                                >
                                                    <Home
                                                        {...props}
                                                        online={online}
                                                        id={MANAGEID}
                                                        back
                                                        title={'machines_menu'}
                                                    >
                                                        <MachineInfo
                                                            id={props.match.params.local}
                                                            area={props.match.params.area}
                                                            machineId={props.match.params.machine}
                                                        />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/catalogs"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '62']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={MANAGEID}
                                                        online={online}
                                                        back
                                                        title={'manage_catolog_menu'}
                                                    >
                                                        <Catalogs />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/catalogs/:catalog"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '62']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={MANAGEID}
                                                        online={online}
                                                        back
                                                        title={'manage_catolog_menu'}
                                                    >
                                                        <Catalog id={props.match.params.catalog} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/new_catalog"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '62']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={MANAGEID}
                                                        online={online}
                                                        back
                                                        title={'manage_catolog_menu'}
                                                    >
                                                        <Catalog />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/customers"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20']} licencias={undefined}>
                                                    <Home
                                                        {...props}
                                                        id={MANAGEID}
                                                        online={online}
                                                        back
                                                        title={'manage_customer_menu'}
                                                    >
                                                        <Customers />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/customers/:customer"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20']} licencias={undefined}>
                                                    <Home
                                                        {...props}
                                                        id={MANAGEID}
                                                        online={online}
                                                        back
                                                        title={'manage_customer_menu'}
                                                    >
                                                        <Customer id={props.match.params.customer} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/new_customer"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20']} licencias={undefined}>
                                                    <Home
                                                        {...props}
                                                        id={MANAGEID}
                                                        online={online}
                                                        back
                                                        title={'manage_new_customer_menu'}
                                                    >
                                                        <Customer />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/termometres"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={MANAGEID}
                                                        online={online}
                                                        back
                                                        title={'manage_termometre_menu'}
                                                    >
                                                        <Termometres />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/termometres/:termometre"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={MANAGEID}
                                                        online={online}
                                                        back
                                                        title={'manage_termometre_menu'}
                                                    >
                                                        <Termometre id={props.match.params.termometre} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/new_termometre"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={MANAGEID}
                                                        online={online}
                                                        back
                                                        title={'manage_new_termometre_menu'}
                                                    >
                                                        <Termometre />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/displays"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '80', '217']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={MANAGEID}
                                                        online={online}
                                                        back
                                                        title={'manage_display_menu'}
                                                    >
                                                        <Displays />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/displays/callback"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '80', '217']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={MANAGEID}
                                                        online={online}
                                                        back
                                                        title={'manage_display_menu'}
                                                    >
                                                        <DisplaysCallback />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/displays/:display"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '80', '217']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={MANAGEID}
                                                        online={online}
                                                        back
                                                        title={'manage_display_menu'}
                                                    >
                                                        <DisplayEdit id={props.match.params.display} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/new_display"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '80', '217']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={MANAGEID}
                                                        online={online}
                                                        back
                                                        title={'manage_new_display_menu'}
                                                    >
                                                        <Display />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/providers"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '62']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={MANAGEID}
                                                        online={online}
                                                        back
                                                        title={'manage_provider_menu'}
                                                    >
                                                        <Providers />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/providers/:id"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '62']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={MANAGEID}
                                                        online={online}
                                                        back
                                                        title={'manage_provider_menu'}
                                                    >
                                                        <Provider id={props.match.params.id} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/new_providers"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '62']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={MANAGEID}
                                                        online={online}
                                                        back
                                                        title={'manage_provider_menu'}
                                                    >
                                                        <Provider />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/templates"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '62']} licencias={[151]}>
                                                    <Home
                                                        {...props}
                                                        id={MANAGEID}
                                                        online={online}
                                                        back
                                                        title={'manage_template_menu'}
                                                    >
                                                        <Templates />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/templates/:id"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '62']} licencias={[151]}>
                                                    <Home
                                                        {...props}
                                                        id={MANAGEID}
                                                        online={online}
                                                        back
                                                        title={'manage_template_menu'}
                                                    >
                                                        <Template id={props.match.params.id} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/new_template"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '62']} licencias={[151]}>
                                                    <Home
                                                        {...props}
                                                        id={MANAGEID}
                                                        online={online}
                                                        back
                                                        title={'manage_template_menu'}
                                                    >
                                                        <Template />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/references"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '64']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={MANAGEID}
                                                        online={online}
                                                        back
                                                        title={'manage_reference_menu'}
                                                    >
                                                        <References />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/references/:id"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '64']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={MANAGEID}
                                                        online={online}
                                                        back
                                                        title={'manage_reference_menu'}
                                                    >
                                                        <Reference id={props.match.params.id} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/new_reference"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '64']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={MANAGEID}
                                                        online={online}
                                                        back
                                                        title={'manage_reference_menu'}
                                                    >
                                                        <Reference />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/roles"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '110', '111']} licencias={[152]}>
                                                    <Home
                                                        {...props}
                                                        id={MANAGEID}
                                                        online={online}
                                                        back
                                                        title={'roles_menu'}
                                                        param={true}
                                                    >
                                                        <Roles />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/roles/new"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '110', '111']} licencias={[152]}>
                                                    <Home
                                                        {...props}
                                                        id={MANAGEID}
                                                        online={online}
                                                        back
                                                        title={'roles_menu'}
                                                        param={true}
                                                    >
                                                        <Roles add />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/roles/clone/:id"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '110', '111']} licencias={[152]}>
                                                    <Home
                                                        {...props}
                                                        id={MANAGEID}
                                                        online={online}
                                                        back
                                                        title={'roles_menu'}
                                                        param={true}
                                                    >
                                                        <Roles clone={props.match.params.id} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/roles/:id"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '110', '111']} licencias={[152]}>
                                                    <Home
                                                        {...props}
                                                        id={MANAGEID}
                                                        online={online}
                                                        back
                                                        title={'roles_menu'}
                                                        param={true}
                                                    >
                                                        <Roles id={props.match.params.id} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/process"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '62']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={MANAGEID}
                                                        online={online}
                                                        back
                                                        title={'manage_checks_menu'}
                                                    >
                                                        <ManageChecks code={1} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/process/:family"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '62']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={MANAGEID}
                                                        online={online}
                                                        title={'manage_checks_menu'}
                                                        param={props.match.params.family}
                                                    >
                                                        <ManageChecks
                                                            code={2}
                                                            family={parseInt(props.match.params.family, 10)}
                                                        />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/process/:family/:process"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '62']} licencias={[150]}>
                                                    <Home
                                                        {...props}
                                                        id={MANAGEID}
                                                        online={online}
                                                        title={'manage_checks_menu'}
                                                        param={props.match.params.process}
                                                    >
                                                        <ManageCheckForms
                                                            code={3}
                                                            family={parseInt(props.match.params.family, 10)}
                                                            process={props.match.params.process}
                                                        />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/tags/texts"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '168']} licencias={[166]}>
                                                    <Home
                                                        {...props}
                                                        back
                                                        id={MANAGEID}
                                                        online={online}
                                                        title={'tags_menu_texts'}
                                                    >
                                                        <ManageTagsText />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/xef/text/:texts"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '168']} licencias={[166]}>
                                                    <Home
                                                        {...props}
                                                        back
                                                        id={MANAGEID}
                                                        online={online}
                                                        title={'tags_menu_texts'}
                                                        param={props.match.params.texts}
                                                    >
                                                        <ManageTagsText param={props.match.params.texts} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/tags/products"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '168']} licencias={[166]}>
                                                    <Home
                                                        {...props}
                                                        back
                                                        id={MANAGEID}
                                                        online={online}
                                                        title={'tags_menu_products'}
                                                    >
                                                        <ManageTagsProducts />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/manage/tags/products/:product"
                                            component={(props) => (
                                                <ProtectRoute permisos={['20', '168']} licencias={[166]}>
                                                    <Home
                                                        {...props}
                                                        back
                                                        id={MANAGEID}
                                                        online={online}
                                                        title={'tags_menu_products'}
                                                        param={props.match.params.product}
                                                    >
                                                        <ManageTagsProducts param={props.match.params.product} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/communications"
                                            component={(props) => (
                                                <ProtectRoute permisos={undefined} licencias={[153]}>
                                                    <Home
                                                        {...props}
                                                        online={online}
                                                        id={COMMUNICATIONS}
                                                        title={'manage_communications'}
                                                    >
                                                        <Communications />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/communications/sent"
                                            component={(props) => (
                                                <ProtectRoute permisos={undefined} licencias={[153]}>
                                                    <Home
                                                        {...props}
                                                        online={online}
                                                        id={COMMUNICATIONS}
                                                        back
                                                        title={'manage_communications'}
                                                    >
                                                        <Communications sent />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/communications/receive"
                                            component={(props) => (
                                                <ProtectRoute permisos={undefined} licencias={[153]}>
                                                    <Home
                                                        {...props}
                                                        online={online}
                                                        id={COMMUNICATIONS}
                                                        back
                                                        title={'manage_communications'}
                                                    >
                                                        <Communications receive />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/communications/sent/new"
                                            component={(props) => (
                                                <ProtectRoute permisos={undefined} licencias={[153]}>
                                                    <Home
                                                        {...props}
                                                        online={online}
                                                        id={COMMUNICATIONS}
                                                        back
                                                        title={'communication_new_message'}
                                                    >
                                                        <Communications sent isnew />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/communications/sent/:id"
                                            component={(props) => (
                                                <ProtectRoute permisos={undefined} licencias={[153]}>
                                                    <Home
                                                        {...props}
                                                        online={online}
                                                        id={COMMUNICATIONS}
                                                        back
                                                        title={'manage_communications'}
                                                    >
                                                        <Communications sent param={props.match.params.id} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/communications/receive/:id"
                                            component={(props) => (
                                                <ProtectRoute permisos={undefined} licencias={[153]}>
                                                    <Home
                                                        {...props}
                                                        online={online}
                                                        id={COMMUNICATIONS}
                                                        back
                                                        title={'manage_communications'}
                                                    >
                                                        <Communications receive param={props.match.params.id} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/help"
                                            component={(props) => (
                                                <Home {...props} online={online} title={'help_menu'}>
                                                    <Help />
                                                </Home>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/me"
                                            component={(props) => (
                                                <Home {...props} online={online} back title={'edit_user_drawer'}>
                                                    <Me />
                                                </Home>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/telegram/:id"
                                            component={(props) => (
                                                <Home {...props} online={online} back title={'Telegram'}>
                                                    <Telegram id={props.match.params.id} />
                                                </Home>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/pac/:id"
                                            component={(props) => (
                                                <Home {...props} online={online} back title={'PAC'}>
                                                    <Pac param={props.match.params.id} />
                                                </Home>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/pac/:id/:field"
                                            component={(props) => (
                                                <Home {...props} online={online} back title={'PAC'}>
                                                    <Pac
                                                        param={props.match.params.id}
                                                        field={props.match.params.field}
                                                    />
                                                </Home>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/documents/:paths?"
                                            component={(props) => (
                                                <ProtectRoute licencias={[172]}>
                                                    <Home {...props} online={online} back title={'documents_title'}>
                                                        <Documents params={props.match.params} />
                                                    </Home>
                                                </ProtectRoute>
                                            )}
                                        />

                                        <Route exact path="/test" component={Test} />
                                        <Route path="*" component={NotFound} />
                                    </Switch>
                                </Router>
                            </div>
                            <div
                                style={divStyle}
                                className="message_rotate fullscreen center bg_primary_super_light img"
                            >
                                <span className="barlow_medium center c_primary margin_top_400">
                                    Rotate your screen
                                </span>
                            </div>
                            <SplashScreen data={warnings} />
                            <Session {...this.props} offline={!online} />
                            <Sse {...this.props} />
                            <Printer {...this.props} />
                        </Suspense>
                    </div>
                )}
            />
        )
    }
}

App.propTypes = {}

const mapStateToProps = (state) => {
    return {
        warnings: state.avisosLogin,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(Object.assign({}, actionsSession, actionsAlert), dispatch)
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(App))

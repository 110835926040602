import * as types from 'const/action.types'

export default (state = 0, action) => {
    switch (action.type) {
        case types.SET_INVENTORY_WINDOW:
            return action.value
        default:
            return state
    }
}

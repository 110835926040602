import React from "react";
import LinearProgress from '@mui/material/LinearProgress';
import Dialog from "@mui/material/Dialog";


// eslint-disable-next-line
import style from './_styles.css'

class LoadingDialog extends React.Component {

    render() {
        return (
            <Dialog
                fullScreen={true}
                open={true}
                onClose={this.props.handleClose}
                className={"z_index_dialog loading_container"}
                classes={{ paper: "opacity" }}
            >
                <LinearProgress />
                <div className="bg_white_65 loading_block"/>
            </Dialog>
        );
    }
}

export default LoadingDialog;

import * as types from 'const/action.types'

export function report_sts(state = {}, action) {
    switch (action.type) {
        case types.REPORT_STS_FETCH_DATA:
            return action.items
        default:
            return state
    }
}

import { message } from 'reducers/message.reducer'
import { language, session } from 'store/state'
export const getPrinter = async (url) => {
    const request = new Request(url, {
        method: 'GET',
        headers: {
            Authorization: session().token,
            'Accept-Language': language(),
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
    return fetch(request)
        .then((response) => {
            return response.json()
        })
        .then((info) => {
            return info
        })
        .catch((err) => {
            return { status: false }
        })
}

export const getPrinterContent = async (url) => {
    const request = new Request(url, {
        mode: 'no-cors',
        method: 'GET',
        headers: {
            'Access-Control-Allow-Headers': 'privatekey',
        },
    })
    return fetch(request)
        .then((response) => {
            return true
        })
        .catch((err) => {
            return false
        })
}

export const postPrinterZD4XX = async (ip_addr, payload) => {
    const request = new Request(`http://${ip_addr}/pstprnt`, {
        mode: 'no-cors',
        method: 'POST',
        headers: {
            'Access-Control-Allow-Headers': 'privatekey',
        },
        body: atob(payload),
    })
    return fetch(request)
        .then((response) => {
            return true
        })
        .catch((err) => {
            return { status: false }
        })
}

export const getWithoutError = async (url) => {
    const request = new Request(url, {
        method: 'GET',
        headers: {
            Authorization: session().token,
            'Accept-Language': language(),
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
    return fetch(request)
        .then((response) => {
            return response.json()
        })
        .then((info) => {
            if (info.result !== 'success') {
                return undefined
            }
            return info.data
        })
        .catch((error) => {
            throw error
        })
}

export const get = async (url) => {
    return getToken(url, session().token)
}

export const getToken = async (url, token) => {
    const request = new Request(url, {
        method: 'GET',
        headers: {
            Authorization: token,
            'Accept-Language': language(),
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
    return fetch(request)
        .then(async (response) => {
            return { json: await response.json(), status: response.status }
        })
        .then((info) => {
            if (info.json.result !== 'success') {
                console.error(info.json.messages)
                const error = {
                    status: info.status,
                    type: info.json.result,
                    message: info.json.messages.join('<br/>'),
                }
                throw error
            }
            return info.json.data
        })
        .catch((error) => {
            console.error(error, error.status)
            if (error && error.status === 403) {
                window.location.href = '/logout'
            }
            throw error
        })
}

export const getDataAndMessage = async (url) => {
    const request = new Request(url, {
        method: 'GET',
        headers: {
            Authorization: session().token,
            'Accept-Language': language(),
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
    return fetch(request)
        .then((response) => {
            return response.json()
        })
        .then((info) => {
            if (info.result !== 'success') {
                console.error(info.messages)
                const error = { type: info.result, message: info.messages.join('<br/>') }
                throw error
            }
            return info
        })
        .catch((err) => {
            throw err
        })
}

export const put = async (url, body) => {
    const request = new Request(url, {
        method: 'PUT',
        headers: {
            Authorization: session().token,
            'Accept-Language': language(),
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
    return fetch(request)
        .then(async (response) => {
            return { json: await response.json(), status: response.status }
        })
        .then((info) => {
            if (info.json.result !== 'success') {
                console.error(info.json.messages)
                const error = {
                    status: info.status,
                    type: info.json.result,
                    message: info.json.messages.join('<br/>'),
                }
                throw error
            }
            return info.json.data
        })
        .catch((error) => {
            console.error(error, error.status)
            if (error && error.status === 403) {
                window.location.href = '/logout'
            }
            throw error
        })
}

export const post = async (url, body) => {
    const request = new Request(url, {
        method: 'POST',
        headers: {
            Authorization: session().token,
            'Accept-Language': language(),
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
    return fetch(request)
        .then(async (response) => {
            return { json: await response.json(), status: response.status }
        })
        .then((info) => {
            if (info.json.result !== 'success') {
                console.error(info.json.messages)
                const error = {
                    status: info.status,
                    type: info.json.result,
                    message: info.json.messages.join('<br/>'),
                }
                throw error
            }
            return info.json.data
        })
        .catch((error) => {
            console.error(error, error.status)
            if (error && error.status === 403) {
                window.locatio.href = '/logout'
            }
            throw error
        })
}

export const postPrinter = async (url, body) => {
    const request = new Request(url, {
        method: 'POST',
        headers: {
            Authorization: session().token,
            'Accept-Language': language(),
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
    return fetch(request)
        .then((response) => {
            return response.json()
        })
        .then((info) => {
            return info.data
        })
        .catch((err) => {
            throw err
        })
}

export const del = async (url) => {
    const request = new Request(url, {
        method: 'DELETE',
        headers: {
            Authorization: session().token,
            'Accept-Language': language(),
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
    return fetch(request)
        .then(async (response) => {
            return { json: await response.json(), status: response.status }
        })
        .then((info) => {
            if (info.json.result !== 'success') {
                console.error(info.json.messages)
                const error = {
                    status: info.status,
                    type: info.json.result,
                    message: info.json.messages.join('<br/>'),
                }
                throw error
            }
            return info.json.data
        })
        .catch((error) => {
            console.error(error, error.status)
            if (error && error.status === 401) {
                window.location = '/logout'
            }
            throw error
        })
}

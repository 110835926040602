import * as types from 'const/action.types'

export function provider(state = {}, action) {
    switch (action.type) {
        case types.LOAD_PROVIDER_SUCCESS:
            return action.data
        default:
            return state
    }
}

export function providers(state = [], action) {
    switch (action.type) {
        case types.PROVIDERS_SUCCESS:
            return action.data
        default:
            return state
    }
}

import * as types from 'const/action.types'

export function recipes(state = [], action) {
    switch (action.type) {
        case types.LOAD_RECIPES_SUCCESS:
            return action.data
        default:
            return state
    }
}

export function recipe(state = {}, action) {
    switch (action.type) {
        case types.LOAD_RECIPE_SUCCESS:
            return action.data
        default:
            return state
    }
}

export function recipe_elaborations(state = [], action) {
    switch (action.type) {
        case types.LOAD_RECIPE_ELABORATIONS_SUCCESS:
            return action.data
        default:
            return state
    }
}

import * as types from 'const/action.types'

export function report_productivity(state = {}, action) {
    switch (action.type) {
        case types.REPORT_PRODUCTIVITY_FETCH_DATA:
            return action.items
        default:
            return state
    }
}
